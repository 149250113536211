import {
    DashboardTypes,
    MetricsInfo,
    MetricsResponse,
    PlaceholderObject,
    UserQuery,
    calculatedMetricsDetail,
    detailedDimensionsDetail,
    metric,
    metricFunction,
    queryInput,
} from 'API';
import { Add, StyledTableContainer, TxtContainer2 } from 'components/Main/table/TableStyles';
import { CCData, useDataQuery } from 'customHooks/useDataQuery';
import { fullSources } from 'customHooks/useDataSources';
import { useGetMainTotalQuery } from 'customHooks/useGetMainTotalQuery';
import { useGetStoredQuery } from 'customHooks/useGetStoredQuery';
import { platformLogoSelector } from 'methods/platformLogoSelector';

import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import PlatformContainer from './PlatformContainer';
import DimensionsContainer from './DimensionsContainer';
import MetricsContainer from './MetricsContainer';
import ConversionsContainer from './ConversionsContainer';
import OverviewContainer from './OverviewContainer';
import AuthContext from 'context/AuthContext';
import { prepQuery, prepQueryCC } from 'methods/prepQuery';
import { useCampaignContext } from 'context/CampaignContext';
import { useJoinData } from 'customHooks/useJoinData';
import SavedContainer from './SavedContainer';
import PreviewContainer from './PreviewContainer';
import { useTemplateContext } from 'context/TemplateContext';
import ReactLoading from 'react-loading';
import { useTheme } from 'styled-components';
import { PublishContext } from 'context/PublishContext';
import { updateCustomSource } from 'methods/updateCustomSource';

export type PlaceholderType = {
    type: 'Chart' | 'Table';
    minor: number;
    id: string;
    query_id?: string;
    duplicated?: boolean;
    original_id?: string;
    original_minor?: string;
    new_position?: string;
    saved?: boolean;
};

type Props = {
    storedQueryDetails?: UserQuery;
    dataSources: fullSources[];
    componentID: string;
    isGraph: boolean;
    placeholderId?: string;
    queryId?: string;
    placeholderList?: PlaceholderType[];
    handleDuplicate?: (componentID: string, mainQueryID: string) => Promise<boolean>;
    element?: PlaceholderType;
    parentIndex?: number;
    orderLength?: number;
    moveElement?: (id: string, direction: 'UP' | 'DOWN') => void;
    isDragEnabled?: boolean;
    setTablePlaceholderList?: (value: React.SetStateAction<PlaceholderType[]>) => void;
    setStoredQueries?: React.Dispatch<React.SetStateAction<UserQuery[]>>;
    removeItemFromList?: (id: string) => void;
    savePlaceholderToOrder2?: (componentId: string) => void;
    updateTablePlaceholderDuplicate?: (queryId: string, id: string, isDupicated: boolean | undefined, newOriginalId?: string | undefined) => void

};

export type placeholderMetrics = {
    metric?: string;
    description?: string | null | undefined;
    view?: (string | null)[] | null | undefined;
    isSelected?: boolean;
    detailedMetric?: metric;
};

export type placeholderDimensions = {
    dimension?: string;
    description?: string | null | undefined;
    view?: (string | null)[] | null | undefined;
    isSelected?: boolean;
    detailedDimension?: detailedDimensionsDetail;
};

export type placeholderFullSources = {
    isSelected?: boolean;
    source_name?: string;
    metrics?: placeholderMetrics[];
    dimensions?: placeholderDimensions[];
    name: string;
    icon?: JSX.Element;
    metaName: string;
    calcMetrics: Array<calculatedMetricsDetail & { isSelected?: boolean }>;
    customConversions?: Array<CCData>;
};

// const PlaceholderContainer = (props: Props) => {
const PlaceholderContainer = (props: Props) => {
    const { user } = useContext(AuthContext);
    const { activeCampaign, dashType } = useCampaignContext();
    const { fetchedPlaceholders } = useTemplateContext();
    const {
        storedQueryDetails,
        dataSources,
        componentID,
        isGraph,
        placeholderId,
        queryId,
        placeholderList,
        handleDuplicate,
        element,
        parentIndex,
        orderLength,
        moveElement,
        isDragEnabled,
        setTablePlaceholderList,
        setStoredQueries,
        removeItemFromList,
        savePlaceholderToOrder2,
        updateTablePlaceholderDuplicate
    } = props;
    const [placeHolderDataSource, setPlaceHolderDataSource] = useState<placeholderFullSources[]>([]);
    const [title, setTitle] = useState<any>('Add title here');
    const [chartMetrics, setChartMetrics] = useState<Array<MetricsResponse>>([]);
    const [queryError, setQueryError] = useState<string>('');
    const theme = useTheme();
    const [newQuery, setNewQuery] = useState<"OLD" | "NEW" | "BACK" | "APPLY">("OLD");
    const [page, setPage] = useState<number>(0);

    const isPlaceholderSaved = element?.saved

    // THESE ARE STATES FOR  4 QUERIES
    const [userMainQuery, setUserMainQuery] = useState<queryInput>();
    const [userMainQueryTotal, setUserMainQueryTotal] = useState<queryInput>();
    const [userCCQuery, setUserCCQuery] = useState<queryInput>();
    const [userCCTotalQuery, setUserCCTotalQuery] = useState<queryInput>();

    // CREATE QUERIES BASED ON STORED QUERYID
    const [mainQuery] = useGetStoredQuery(storedQueryDetails?.query_id as string);
    const [mainQueryTotal] = useGetMainTotalQuery(mainQuery as queryInput, 'MAIN');
    const [ccQuery] = useGetStoredQuery(storedQueryDetails?.query_id_cc as string);
    // OVO ISPOD NE RADI JER TOTAL CC SE NE PRIKAZUJE; IAKO DOBIJEM I QUERY I DATU
    const [ccQueryTotal] = useGetMainTotalQuery(ccQuery as queryInput, 'CC');
    const { updateOLArray, removePlaceholderfromPublishArray } = useContext(PublishContext);
    const [defaultSelected, setDefaultSelected] = useState<boolean>(false);

    //IF STORED QUERY IS THERE, SET THE QUERIES
    useEffect(() => {
        if (mainQuery) {
            setUserMainQuery(mainQuery);
        }
    }, [mainQuery]);
    useEffect(() => {
        if (mainQueryTotal) {
            setUserMainQueryTotal(mainQueryTotal);
        }
    }, [mainQueryTotal]);
    useEffect(() => {
        if (ccQuery) {
            setUserCCQuery(ccQuery);
        }
    }, [ccQuery]);
    useEffect(() => {
        if (ccQueryTotal) {
            setUserCCTotalQuery(ccQueryTotal);
        }
    }, [ccQueryTotal]);

    // THESE ARE DATA RESULTS FROM USER GENERATED  QUERIES
    const [mainData, mainQueryStatus, mainError, mainQueryID, mainOL] = useDataQuery({
        query: userMainQuery,
        querySource: 'QUERY',
    });
    const [mainTotalData, mainTotalQueryStatus, , , mainOLTotal] = useDataQuery({
        query: userMainQueryTotal,
        querySource: 'QUERY TOTAL',
    });
    const [ccData, ccQueryStatus, , ccQueryID, ccOL] = useDataQuery({ query: userCCQuery, querySource: 'QUERY' });
    const [ccTotalData, ccTotalQueryStatus, , , ccOLTotal] = useDataQuery({
        query: userCCTotalQuery,
        querySource: 'QUERY TOTAL',
    });


    // READ STORED QUERIES AND IDENTIFY SELECTED PÆATFORM; METRICS; DIMENSIONS; CALC METRICS
    useEffect(() => {
        const lookupPlaceHolderName = (viewName: string) => {
            if (viewName.includes('adform')) {
                return 'Adform';
            } else if (viewName.includes('facebook')) {
                return 'Facebook';
            } else if (viewName.includes('dv360')) {
                return 'DV360';
            } else if (viewName.includes('google_ads_video')) {
                return 'Youtube';
            } else if (viewName.includes('campaign_manager')) {
                return 'CM';
            } else if (viewName.includes('linkedin')) {
                return 'LinkedIn';
            } else if (viewName.includes('_tv_')) {
                return 'TV';
            } else if (viewName.includes('_radio_')) {
                return 'RADIO';
            } else if (viewName.includes('print')) {
                return 'PRINT';
            } else if (viewName.includes('outdoor')) {
                return 'OOH';
            } else if (viewName.includes("cinema")) {
                return "CINEMA";
            } else if (viewName.includes('snapchat')) {
                return 'Snapchat';
            } else if (viewName.includes('tiktok')) {
                return 'TikTok';
            } else if (viewName.includes('readpeak')) {
                return 'readpeak';
            } else throw new Error('No platform found');
        };


        if (mainQuery && dataSources.length > 0 && (storedQueryDetails?.position === componentID || storedQueryDetails?.position === placeholderId)) {
            const activeSourceInfo = mainQuery.views[0]?.viewName ?? '';
            const metrics = mainQuery.query.metrics;
            const dimensions = mainQuery.query.detailedDimensions;
            const calcMetrics = mainQuery.query.calculatedMetrics;
            const clonedDataSources = dataSources.map((el) => structuredClone(el)) as placeholderFullSources[];
            setTitle(storedQueryDetails?.title);
            const metricsArray = storedQueryDetails?.metrics;
            //remove duplicates from metricsArray
            const uniqueMetrics = metricsArray?.filter((v, i, a) => a.findIndex((t) => t?.metric === v?.metric) === i);
            setChartMetrics(uniqueMetrics as MetricsResponse[]);
            clonedDataSources.map((el) => {
                return { ...el, icon: platformLogoSelector(el.name) };
            });
            if (activeSourceInfo === '') {
                setPlaceHolderDataSource(clonedDataSources);
            } else {
                const dataSourcesWithSelectedPlatform = clonedDataSources.map((el) => {
                    if (el.name === lookupPlaceHolderName(activeSourceInfo)) {
                        return { ...el, isSelected: true };
                    } else return { ...el, isSelected: false };
                });

                if (dataSourcesWithSelectedPlatform.every((el) => el.isSelected === false)) {
                    const Pname = lookupPlaceHolderName(activeSourceInfo);
                    setQueryError(`${Pname} is not available for this campaign`);
                } else {
                    setQueryError('');
                    const dataSourcesWithSelectedMetrics = dataSourcesWithSelectedPlatform.map((el) => {
                        if (el.isSelected) {
                            const newMetrics = el.metrics?.map((el) => {
                                const findMetric = metrics?.find((metric) => metric?.column === el.metric);
                                if (findMetric) {
                                    el.isSelected = true;
                                    el.detailedMetric = findMetric;
                                    return el;
                                } else return { ...el, isSelected: false };
                            });

                            const newDimensions = el.dimensions?.map((el) => {
                                const findDimension = dimensions?.find((dimension) => dimension?.column === el.dimension);
                                if (findDimension) {
                                    el.isSelected = true;
                                    el.detailedDimension = findDimension;

                                    return el;
                                } else return { ...el, isSelected: false };
                            });
                            const newCalcMetrics = el.calcMetrics?.map((el) => {
                                const findCalcMetric = calcMetrics?.find((calcMetric) => calcMetric?.query === el.query);
                                if (findCalcMetric) {
                                    el.isSelected = true;
                                    el.id = Math.floor(Math.random() * Date.now()).toString();
                                    // el.name = findCalcMetric.name;

                                    return el;
                                } else return { ...el, isSelected: false };
                            });
                            return { ...el, metrics: newMetrics, dimensions: newDimensions, calcMetrics: newCalcMetrics };
                        } else return el;
                    });
                    if (ccQuery) {
                        const ccFilter = ccQuery.query.filters?.find(
                            (el) => el?.column === 'conversion_name' || el?.column === 'filter_name' || el?.column === 'activity' || el?.column === 'floodlight_activity_name',
                        );
                        if (ccFilter) {
                            const ccMetricsList = ccFilter?.filter?.list;
                            const dataSurcesWithSelectedMetricsAndCC = dataSourcesWithSelectedMetrics.map((el) => {
                                if (el.isSelected) {
                                    const newCC = el.customConversions?.map((el) => {
                                        if (ccMetricsList?.includes(el.CCname)) {
                                            return { ...el, isSelected: true };
                                        } else return { ...el, isSelected: false };
                                    });
                                    return { ...el, customConversions: newCC };
                                } else return el;
                            });
                            const selectedplatform = dataSurcesWithSelectedMetricsAndCC.find((el) => el.isSelected);
                            if (selectedplatform?.customConversions?.every((el) => el.isSelected === false)) {
                                setQueryError(`Some or all custom conversions are not available for this campaign`);
                            } else {
                                setPlaceHolderDataSource(dataSurcesWithSelectedMetricsAndCC);
                            }
                        }
                    } else {
                        setPlaceHolderDataSource(dataSourcesWithSelectedMetrics);
                    }
                }
            }

        } else {
            removePlaceholderfromPublishArray(componentID);
            // setPage(0);
            setPlaceHolderDataSource([]);

        } /* else if (!mainQuery && dataSources.length > 0) {
            if (placeholderId === "101" || placeholderId === "21") {
                startNewQuery()
            }
        } */

    }, [ccQuery, dataSources, mainQuery]);

    // CUSTOM HOOK IN WHICH WE SEND DATA, STATUS, ISGRAPH AND CREATE A DATA JSON
    const [joinedData] = useJoinData(
        mainData as any[] | undefined,
        mainTotalData as any[] | undefined,
        ccData as any[] | undefined,
        ccTotalData as any[] | undefined,
        isGraph,
        mainQueryStatus,
        mainTotalQueryStatus,
        ccQueryStatus,
        ccTotalQueryStatus,
        chartMetrics,
        setChartMetrics,
        componentID,
        newQuery,
        defaultSelected
    );

    // console.log(componentID, userMainQuery)
    // console.log("placeHolderDataSource", placeHolderDataSource)


    const [publishPlaceholderObject, setPublishPlaceholderObject] = useState<PlaceholderObject>();

    const nextPage = () => {
        setPage(page + 1);
    };

    const previousPage = () => {
        setPage(page - 1);
    };
    // START QUERY ON CLICK AND ADD ICONS TO DATA SOURCES
    const startNewQuery = () => {
        const clonedDataSources = dataSources.map((el) => {
            return {
                ...structuredClone(el),
                icon: platformLogoSelector(el.name),
            } as placeholderFullSources;
        });
        setPlaceHolderDataSource(clonedDataSources);
        setPage(1);
    };
    const [newPlatformSelected, setNewPlatformSelected] = useState<boolean>(false);
    // SELECT PLATFORM
    const selectSource = (event: ChangeEvent<HTMLInputElement>) => {

        if (userMainQuery) {
            setUserMainQuery(undefined)
            setNewPlatformSelected(true);
        }

        const addSource = () => {




            const listWithAddeDSelected = placeHolderDataSource.map((el) => {
                if (el.name === event.target.value) {
                    return { ...el, isSelected: true };
                } else {
                    if (el.isSelected) {
                        return el;
                    } else {
                        return { ...el, isSelected: false };
                    }
                }
            });




            const howManySelected = listWithAddeDSelected.filter((el) => el.isSelected).length;
            if (howManySelected === 1) {
                setPlaceHolderDataSource(listWithAddeDSelected);
            } else if (howManySelected > 1) {
                // Logic THAT WILL SELECT A CUSTOM PLATFORM THAT IS A COMBINATION OF SELECTED PLATFORMS
                const q = updateCustomSource(listWithAddeDSelected);
                setPlaceHolderDataSource(q as placeholderFullSources[]);
            }

        }


        const removeSource = () => {
            const a = placeHolderDataSource.map((el) => {
                if (el.name === event.target.value) {
                    return { ...el, isSelected: false };
                } else {
                    if (el.isSelected) {
                        return el;
                    } else {
                        return { ...el, isSelected: false };
                    }
                }
            });
            const howManySelected = a.filter((el) => el.isSelected).length;
            if (howManySelected < 2) {
                setPlaceHolderDataSource(a);
            } else if (howManySelected > 1) {
                const q = updateCustomSource(a);
                setPlaceHolderDataSource(q as placeholderFullSources[]);
            }

        }




        event.target.checked ? addSource() : removeSource();


        // placeHolderDataSource.map((el) => (el.name === source ? (el.isSelected = true) : (el.isSelected = false)));
        // // add isSelectedFalse property to all metrics and dimensions and calcMetrics
        // const a = placeHolderDataSource.map((el) => {
        //     if (el.isSelected) {
        //         const newMetrics = el.metrics?.map((el) => {
        //             return { ...el, isSelected: false };
        //         });
        //         const newDimensions = el.dimensions?.map((el) => {
        //             return { ...el, isSelected: false };
        //         });
        //         const newCalcMetrics = el.calcMetrics?.map((el) => {
        //             return { ...el, isSelected: false };
        //         });
        //         return { ...el, metrics: newMetrics, dimensions: newDimensions, calcMetrics: newCalcMetrics };
        //     } else return el;
        // });
        // setPlaceHolderDataSource(a);

    };
    // CONTUNUUE AFTER SELECTING PLAFORMS
    const handlePlatformsContinue = () => {
        const selectedPlatform = placeHolderDataSource.filter((el) => el.isSelected);
        if (selectedPlatform.length === 1) {

        } else if (selectedPlatform.length > 1) {
            const a = placeHolderDataSource.map(el => {
                if (el.name === "Custom") {
                    return { ...el, isSelected: true };
                } else {
                    return { ...el, isSelected: false };
                }
            })
            setDefaultSelected(false);
            setPlaceHolderDataSource(a);

        }
        nextPage();

    }



    // SELECT DIMENSION
    const selectDimension = (event: ChangeEvent<HTMLInputElement>) => {
        const addDimension = () => {
            const detailedDimension = {
                column: event.target.name,
                hidden: false,
                id: Math.floor(Math.random() * Date.now()).toString(),
                name: event.target.name,
            };

            const a = placeHolderDataSource.map((el) => {
                if (el.isSelected) {
                    const newDimensions = el.dimensions?.map((el) => {
                        if (el.dimension === event.target.name) {
                            return {
                                ...el,
                                isSelected: true,
                                detailedDimension: detailedDimension,
                            };
                        } else {
                            return el;
                        }
                    });
                    return { ...el, dimensions: newDimensions };
                } else return el;
            });
            setPlaceHolderDataSource(a);
        };

        const removeDimension = () => {
            const a = placeHolderDataSource.map((el) => {
                if (el.isSelected) {

                    const newDimensions = el.dimensions?.map((el) => {
                        if (el.dimension === event.target.name) {

                            delete el.detailedDimension;
                            return {
                                ...el,
                                isSelected: false,
                            };
                        } else {
                            return el;
                        }
                    });
                    return { ...el, dimensions: newDimensions };
                } else return el;
            });
            setPlaceHolderDataSource(a);
        };

        event.target.checked ? addDimension() : removeDimension();
    };
    //GOING BACK TO BLATFORM SELECTion
    // const handleDimensionsBack = () => {
    //     const a = placeHolderDataSource.map((el) => {

    //     setPage(page - 1);
    // }

    const toggleHiddenDimension = (dimensionColumn: string) => {
        const a = placeHolderDataSource.map((el) => {
            if (el.isSelected) {
                const newDimensions = el.dimensions?.map((el) => {
                    if (el.dimension === dimensionColumn) {
                        return { ...el, detailedDimension: { ...el.detailedDimension, hidden: false } };
                    } else return el;
                    // return { ...el, detailedDimension: { ...el.detailedDimension, hidden: false } }
                });
                return { ...el, dimensions: newDimensions };
            } else return el;
        });
        setPlaceHolderDataSource(a as placeholderFullSources[]);
    }
    // SELECT METRIC
    const selectMetric = (event: ChangeEvent<HTMLInputElement>) => {
        const addMetric = () => {
            const detailedMetric: metric = {
                column: event.target.name === 'End date' || event.target.name === 'Start date' ? 'date' : event.target.name,
                name: event.target.name,
                aggregation:
                    event.target.name.endsWith('_reach') ||
                        event.target.name.startsWith('sub_rf_') ||
                        event.target.name === 'effective_frequency' ||
                        event.target.name === 'reach' ||
                        event.target.name === 'frequency' ||
                        event.target.name === 'date' ||
                        event.target.name === 'End date'
                        ? metricFunction.MAX
                        : event.target.name === 'Start date'
                            ? metricFunction.MIN
                            : metricFunction.SUM,
                id: Math.floor(Math.random() * Date.now()).toString(),
                decimals: event.target.name === 'Start date' || event.target.name === 'End date' ? -1 : 2,
            };
            const a = placeHolderDataSource.map((el) => {
                if (el.isSelected) {
                    const newMetrics = el.metrics?.map((el) => {
                        if (el.metric === event.target.name) {
                            return {
                                ...el,
                                isSelected: true,
                                detailedMetric: detailedMetric,
                            };
                        } else {
                            return el;
                        }
                    });
                    return { ...el, metrics: newMetrics };
                } else return el;
            });
            setPlaceHolderDataSource(a);
        };
        const removeMetric = () => {
            const a = placeHolderDataSource.map((el) => {
                if (el.isSelected) {
                    const newMetrics = el.metrics?.map((el) => {
                        if (el.metric === event.target.name) {
                            delete el.detailedMetric;
                            return {
                                ...el,
                                isSelected: false,

                            };
                        } else {
                            return el;
                        }
                    });
                    return { ...el, metrics: newMetrics };
                } else return el;
            });
            setPlaceHolderDataSource(a);
        };
        event.target.checked ? addMetric() : removeMetric();
    };
    // SELECT CALC METRIC
    const selectCalcMetric = (metric: ChangeEvent<HTMLInputElement>) => {
        const addCalcMetric = () => {
            const a = placeHolderDataSource.map((el) => {
                if (el.isSelected) {
                    const newCalcMetrics = el.calcMetrics?.map((el) => {
                        if (el.query === metric.target.value) {
                            return {
                                ...el,
                                isSelected: true,
                                decimals: 2,
                                id: Math.floor(Math.random() * Date.now()).toString(),
                            };
                        } else {
                            return el;
                        }
                    });
                    return { ...el, calcMetrics: newCalcMetrics };
                } else return el;
            });
            setPlaceHolderDataSource(a);
        };
        const removeCalcMetric = () => {
            // if (chartMetrics.length > 0) {
            //     const arr = chartMetrics.filter(el => el.metric !== metric.target.value);
            //     setChartMetrics(arr);
            // }
            const a = placeHolderDataSource.map((el) => {
                if (el.isSelected) {
                    const newCalcMetrics = el.calcMetrics?.map((el) => {
                        if (el.query === metric.target.value) {
                            delete el.decimals;
                            return {
                                ...el,
                                isSelected: false,
                            };
                        } else {
                            return el;
                        }
                    });
                    return { ...el, calcMetrics: newCalcMetrics };
                } else return el;
            });
            setPlaceHolderDataSource(a);
        };
        metric.target.checked ? addCalcMetric() : removeCalcMetric();
    };
    // SELECT CC
    const selectCustomConversion = (event: ChangeEvent<HTMLInputElement>) => {
        const addCC = () => {
            const a = placeHolderDataSource.map((el) => {
                if (el.isSelected) {
                    const newCC = el.customConversions?.map((el) => {
                        if (el.CCname === event.target.name) {
                            return {
                                ...el,
                                isSelected: true,
                            };
                        } else {
                            return el;
                        }
                    });
                    return { ...el, customConversions: newCC };
                } else return el;
            });
            setPlaceHolderDataSource(a);
        };
        const removeCC = () => {
            // if (chartMetrics.length > 0) {
            //     const arr = chartMetrics.filter((el) => el.metric !== event.target.name);
            //     setChartMetrics(arr);
            // }
            const a = placeHolderDataSource.map((el) => {
                if (el.isSelected) {
                    const newCC = el.customConversions?.map((el) => {
                        if (el.CCname === event.target.name) {
                            return {
                                ...el,
                                isSelected: false,
                            };
                        } else {
                            return el;
                        }
                    });
                    return { ...el, customConversions: newCC };
                } else return el;
            });
            setPlaceHolderDataSource(a);
        };
        event.target.checked ? addCC() : removeCC();
    };

    //PREP USER QUERY ON FETCH
    const prepUserQuery = () => {
        const selectedPlatform = placeHolderDataSource.find((el) => el.isSelected);
        delete selectedPlatform?.icon;
        const q = prepQuery(selectedPlatform as placeholderFullSources, isGraph, activeCampaign, user);
        //SET MAIN QUERY
        if (userMainQuery) {
            q.query.detailedDimensions = q.query.detailedDimensions?.map((d) => {
                const findDimension = userMainQuery.query.detailedDimensions?.find((el) => el?.column === d?.column);
                if (findDimension) {
                    // chek if property hidden is different in findDimension and d
                    // if it is different set it to the value of d
                    if (findDimension.hidden !== d?.hidden) {
                        return { ...findDimension, hidden: d?.hidden as boolean };
                    } else {
                        return findDimension;
                    }
                    // return findDimension;
                } else {
                    return d;
                }
            });
            q.query.metrics = q.query.metrics?.map((m) => {
                const findMetric = userMainQuery.query.metrics?.find((el) => el?.column === m?.column);
                if (findMetric) {
                    return findMetric;
                } else {
                    return m;
                }
            });
            // console.log(userMainQuery.query.filters?.map(f => f?.column === "campaign_id" || selectedPlatform?.dimensions?.filter(d => d?.isSelected).some(d => d?.dimension === f?.column) ? f : null).filter(f => f !== null) as any)
            q.query.filters = userMainQuery.query.filters?.map(f => f?.column === "campaign_id" || selectedPlatform?.dimensions?.filter(d => d?.isSelected).some(d => d?.dimension === f?.column) ? f : null).filter(f => f !== null) as any;
        }
        // map  q.query.orderBy and look for the same string in userMainQuery.query.orderBy
        // if it exists place it on the same index as in userMainQuery.query.orderBy
        // if it does not exist place it on the end of the array





        // KEEP RENAMED CALCULATED METRIC WHEN CLICKING BACK
        q.query.calculatedMetrics = q.query.calculatedMetrics?.map((m) => {
            const findMetric = userMainQuery?.query.calculatedMetrics?.find((el) => el?.query === m?.query);
            if (findMetric) {
                return { ...findMetric, id: m?.id };
            } else {
                return m;
            }
        });


        setUserMainQuery(q);

        if (
            selectedPlatform?.customConversions?.length !== 0 &&
            selectedPlatform?.customConversions?.some((el) => el.isSelected)
        ) {
            const CCq = prepQueryCC(selectedPlatform as placeholderFullSources, isGraph, activeCampaign, user);
            // SET CC QUERY
            if (userCCQuery) {
                //CCq.query.filters = userCCQuery.query.filters;
                CCq.query.metrics = CCq.query.metrics?.map((m) => {
                    const findMetric = userCCQuery.query.metrics?.find((el) => el?.column === m?.column);
                    if (findMetric) {
                        return { ...findMetric, id: m?.id };
                    } else {
                        return m;
                    }
                });
                CCq.query.detailedDimensions = CCq.query.detailedDimensions?.map((d) => {
                    const findDimension = userCCQuery.query.detailedDimensions?.find((el) => el?.column === d?.column);
                    if (findDimension) {
                        return { ...findDimension, id: d?.id };
                    } else {
                        return d;
                    }
                });
            }

            // KEEP RENAMED CALCULATED METRIC WHEN CLICKING BACK
            CCq.query.calculatedMetrics = CCq.query.calculatedMetrics?.map((m) => {
                const findMetric = userCCQuery?.query.calculatedMetrics?.find((el) => el?.query === m?.query);
                if (findMetric) {
                    return { ...findMetric, id: m?.id };
                } else {
                    return m;
                }
            });

            setUserCCQuery(CCq);
        } else {
            setUserCCQuery(undefined);
        }

        if (!isGraph) {
            const sourceObjectTotal = structuredClone(selectedPlatform) as placeholderFullSources;
            sourceObjectTotal.dimensions = sourceObjectTotal.dimensions?.map((d) => {
                if (d.dimension === 'campaign_id') {
                    return {
                        ...d,
                        isSelected: true,
                        detailedDimension: {
                            column: 'campaign_id',
                            hidden: false,
                            id: Math.floor(Math.random() * Date.now()).toString(),
                            name: 'campaign_id',
                        },
                    };
                } else {
                    return { ...d, isSelected: false };
                }
            });

            const qTotal = prepQuery(sourceObjectTotal as placeholderFullSources, isGraph, activeCampaign, user);
            if (userMainQueryTotal) {
                // qTotal.query.detailedDimensions = qTotal.query.detailedDimensions?.map((d) => {
                //     const findDimension = userMainQueryTotal.query.detailedDimensions?.find((el) => el?.column === d?.column);
                //     if (findDimension) {
                //         return findDimension;
                //     } else {
                //         return d;
                //     }
                // });
                qTotal.query.metrics = qTotal.query.metrics?.map((m) => {
                    const findMetric = userMainQueryTotal.query.metrics?.find((el) => el?.column === m?.column);
                    if (findMetric) {
                        return findMetric;
                    } else {
                        return m;
                    }
                });
                // qTotal.query.filters = userMainQueryTotal.query.filters;
            }
            qTotal.query.calculatedMetrics = qTotal.query.calculatedMetrics?.map((m) => {
                const findMetric = userMainQueryTotal?.query.calculatedMetrics?.find((el) => el?.query === m?.query);
                if (findMetric) {
                    return { ...findMetric, id: m?.id };
                } else {
                    return m;
                }
            });
            const isFacebook = qTotal.views[0]?.viewName.includes("facebook") ? true : false
            qTotal.query.metrics = qTotal.query.metrics?.map((element: any) => (element.column.endsWith("reach") && isFacebook) ? { ...element, column: "campaign_mapping_reach" } : element)


            setUserMainQueryTotal(qTotal);
            if (
                selectedPlatform?.customConversions?.length !== 0 &&
                selectedPlatform?.customConversions?.some((el) => el.isSelected)
            ) {
                const CCqTotal = prepQueryCC(sourceObjectTotal as placeholderFullSources, isGraph, activeCampaign, user);
                if (userCCTotalQuery) {
                    CCqTotal.query.metrics = CCqTotal.query.metrics?.map((m) => {
                        const findMetric = userCCTotalQuery.query.metrics?.find((el) => el?.column === m?.column);
                        if (findMetric) {
                            return { ...findMetric, id: m?.id };
                        } else {
                            return m;
                        }
                    });
                    // CCqTotal.query.detailedDimensions = userCCTotalQuery.query.detailedDimensions?.map((d) => {
                    //     const findDimension = userCCTotalQuery.query.detailedDimensions?.find((el) => el?.column === d?.column);
                    //     if (findDimension) {
                    //         return findDimension;
                    //     } else {
                    //         return d;
                    //     }
                    // });
                }

                // KEEP RENAMED CALCULATED METRIC WHEN CLICKING BACK
                CCqTotal.query.calculatedMetrics = CCqTotal.query.calculatedMetrics?.map((m) => {
                    const findMetric = userCCTotalQuery?.query.calculatedMetrics?.find((el) => el?.query === m?.query);
                    if (findMetric) {
                        return { ...findMetric, id: m?.id };
                    } else {
                        return m;
                    }
                });
                setUserCCTotalQuery(CCqTotal);
            }
        }
        if (newQuery !== "BACK") {
            setNewQuery("NEW");
        }


        if (element?.duplicated === true) {

            updateTablePlaceholderDuplicate && updateTablePlaceholderDuplicate(mainQueryID, element.id, false, element.id)

        }

        setPage(6);
        return q
    };
    // THIS MIGHT BE BACK A BUTTON ISSUE
    useEffect(() => {
        if (storedQueryDetails && !joinedData && !newPlatformSelected) {
            setPage(6);
        } else if (storedQueryDetails && joinedData) {

            setTitle(storedQueryDetails?.title);
            setNewPlatformSelected(false);
            // newQuery ? setPage(6) : setPage(7);

            if (element?.duplicated === true) {
                isPlaceholderSaved === false ? setPage(6) : setPage(7)
            } else {
                setPage((newQuery !== "OLD" || fetchedPlaceholders.length > 0) && (dashType === DashboardTypes.INSOURCE_DATA || dashType === DashboardTypes.DIGITAL) ? 6 : 7)
            }
        }
    }, [storedQueryDetails, joinedData, newQuery, fetchedPlaceholders.length, dashType]);

    // : isPlaceholderSaved === false ? 6 


    // console.log("What page is this one ??", element)





    useEffect(() => {
        // updata olarray was for some reason inside if statement. I didnt delete it because I am not sure if it is needed
        // update olarray used to be inside of the if statemetn, i kept the code because i am not sure if it will work
        if (![mainQueryStatus, mainTotalQueryStatus, ccQueryStatus, ccTotalQueryStatus].includes("RUNNING") &&
            ![mainQueryStatus, mainTotalQueryStatus, ccQueryStatus, ccTotalQueryStatus].includes("FAILED")) {
            if (!isGraph) {
                if (ccQueryStatus === "NOT STARTED") {
                    if (mainOL && mainOLTotal) {
                        const placeholdersObject = {
                            position: placeholderId,
                            outputlocation: mainOL,
                            outputlocationTotal: mainOLTotal,
                            title: storedQueryDetails?.title ?? title,
                        };
                        updateOLArray(placeholdersObject);
                        if (newQuery === "OLD" && storedQueryDetails && fetchedPlaceholders.length === 0) {
                        } else {
                            setPublishPlaceholderObject(placeholdersObject);
                        }
                    }
                } else if (ccQueryStatus === "DONE" && ccTotalQueryStatus === "DONE") {
                    if (mainOL && mainOLTotal && ccOL && ccOLTotal) {
                        const placeholdersObject = {
                            position: placeholderId,
                            outputlocation: mainOL,
                            outputlocationTotal: mainOLTotal,
                            outputlocation_cc: ccOL,
                            outputlocationTotal_cc: ccOLTotal,
                            title: storedQueryDetails?.title ?? title,
                        };
                        updateOLArray(placeholdersObject);
                        if (newQuery === "OLD" && storedQueryDetails && fetchedPlaceholders.length === 0) {
                        } else {
                            setPublishPlaceholderObject(placeholdersObject);
                        }

                    }
                }
            } else if (isGraph /* && chartMetrics.length > 0 */) {
                if (ccQueryStatus === "NOT STARTED") {
                    if (mainOL) {
                        const placeholdersObject = {
                            position: placeholderId,
                            outputlocation: mainOL,
                            title: storedQueryDetails?.title ?? title,
                            metrics: dashType === "DIGITAL" || dashType === "INSOURCE_DATA" ? chartMetrics : [],
                        };
                        updateOLArray(placeholdersObject);
                        if (newQuery === "OLD" && storedQueryDetails && fetchedPlaceholders.length === 0) {
                        } else {
                            setPublishPlaceholderObject(placeholdersObject);
                        }

                    }
                } else if (ccQueryStatus === "DONE") {
                    if (mainOL && ccOL) {
                        const placeholdersObject = {
                            position: placeholderId,
                            outputlocation: mainOL,
                            outputlocation_cc: ccOL,
                            title: storedQueryDetails?.title ?? title,
                            metrics: dashType === "DIGITAL" || dashType === "INSOURCE_DATA" ? chartMetrics : [],
                        };
                        updateOLArray(placeholdersObject);
                        if (newQuery === "OLD" && storedQueryDetails && fetchedPlaceholders.length === 0) {
                            updateOLArray(placeholdersObject);
                            if (newQuery === "OLD" && storedQueryDetails && fetchedPlaceholders.length === 0) {
                            } else {
                                setPublishPlaceholderObject(placeholdersObject);
                            }

                        }
                    }
                }
            }
        }
    }, [ccOL, ccOLTotal, ccQueryStatus, ccTotalQueryStatus, chartMetrics, dashType, fetchedPlaceholders.length, isGraph, mainOL, mainOLTotal, mainQueryStatus, mainTotalQueryStatus, newQuery, placeholderId, storedQueryDetails, title, updateOLArray])


    const isLoading = [mainQueryStatus, mainTotalQueryStatus, ccQueryStatus, ccTotalQueryStatus].some((el) => el === 'RUNNING');



    const defaultQuery = () => {
        const clonedDataSources = dataSources.map((el) => {
            return {
                ...structuredClone(el),
                icon: platformLogoSelector(el.name),
            } as placeholderFullSources;
        });
        const a = clonedDataSources.map((el) => {
            if (el.name === "Custom") {
                return { ...el, isSelected: true };
            } else {
                return { ...el, isSelected: false };
            }
        })

        const metrics = isGraph ? ["impressions"] : ["impressions", "clicks", "cost"];
        const dimensions = isGraph ? ["year_week", "data_source"] : [/* "campaign_name" */ "data_source"];
        const calcMetrics = isGraph ? [] : ["CPC", "CPM"];


        a.map((el) => {
            if (el.isSelected) {
                const newMetrics = el.metrics?.map((el) => {
                    const findMetric = metrics?.find((metric) => metric === el.metric);
                    if (findMetric) {
                        el.isSelected = true;
                        el.detailedMetric = { column: el.metric as string, name: el.metric as string, aggregation: metricFunction.SUM, id: Math.floor(Math.random() * Date.now()).toString(), decimals: 2 };
                        return el;
                    } else return { ...el, isSelected: false };
                });

                const newDimensions = el.dimensions?.map((el) => {
                    const findDimension = dimensions?.find((dimension) => dimension === el.dimension);
                    if (findDimension) {
                        el.isSelected = true;
                        el.detailedDimension = { column: el.dimension as string, name: el.dimension as string, hidden: false, id: Math.floor(Math.random() * Date.now()).toString() };

                        return el;
                    } else return { ...el, isSelected: false };
                });
                const newCalcMetrics = el.calcMetrics?.map((el) => {
                    const findCalcMetric = calcMetrics?.find((calcMetric) => calcMetric === el.name);
                    if (findCalcMetric) {
                        el.isSelected = true;
                        el.id = Math.floor(Math.random() * Date.now()).toString();
                        // el.name = findCalcMetric.name;

                        return el;
                    } else return { ...el, isSelected: false };
                });
                return { ...el, metrics: newMetrics, dimensions: newDimensions, calcMetrics: newCalcMetrics };
            } else return el;
        }
        );
        setTitle(isGraph ? "Pacing" : "Summary")
        setPlaceHolderDataSource(a);
        setDefaultSelected(true);
        // isGraph && setChartMetrics([{
        //     __typename: "MetricsResponse",
        //     chartType: "line",
        //     metric: "impressions",
        //     axis: 0,
        // }])

    }

    useEffect(() => {
        if (defaultSelected) {
            const q = prepUserQuery();
            // if (q) {
            //     setPage(6)
            // }
        }
    }, [defaultSelected])
    // console.log("mainQ", componentID, mainQuery, mainQueryTotal , mainData)




    return (
        <StyledTableContainer
            style={{
                display: 'block',
                border: isDragEnabled ? '1px solid #a1aebe' : 'none',
                borderRadius: '7px',
                // height: /* page === 6 ? (isLoading && isGraph ? '25vw' : 'auto') : isGraph ? page === 0 || page === 1 || page === 7 ? '25vw' : "auto" : */ 'auto',
                maxHeight: page === 6 ? (isLoading && isGraph ? '37vw' : '') : 'auto',
                minHeight: isGraph ? "45vh" : 'auto',
            }}
            expand={0}
        >
            <>
                <div className="upAndDownContainer">
                    {element && isDragEnabled && orderLength && (
                        <>
                            {parentIndex !== 0 && <div onClick={() => moveElement && moveElement(element.id, 'UP')}>UP</div>}
                            {parentIndex !== orderLength - 1 && (
                                <div onClick={() => moveElement && moveElement(element.id, 'DOWN')}>DOWN</div>
                            )}
                        </>
                    )}
                </div>

                {page === 0 && (
                    <TxtContainer2 isGraph={isGraph} displayTxt={page}>
                        <Add isGraph={isGraph}>

                            {(placeholderId === "101" || placeholderId === "201") ?
                                <>
                                    <div className="text">
                                        <h1>No Saved Queries</h1>
                                        <h2>You can start a new query or choose a default one</h2>
                                    </div>
                                    <div className="actionButton">
                                        <button onClick={() => startNewQuery()} type="button">
                                            Start new query +
                                        </button>
                                        <button style={{ marginLeft: "10px" }} onClick={() => defaultQuery()} type="button">
                                            Default Query
                                        </button>
                                    </div>
                                </> :
                                <>
                                    <div className="text">
                                        <h1>No Saved Queries</h1>
                                        <h2>If you would like to start a query, please click on the button </h2>
                                    </div>
                                    <div className="actionButton">
                                        <button onClick={() => startNewQuery()} type="button">
                                            Start new query +
                                        </button>
                                    </div>
                                </>


                            }
                        </Add>
                    </TxtContainer2>
                )}

                {page === 1 && (
                    <PlatformContainer
                        isGraph={isGraph}
                        placeHolderDataSource={placeHolderDataSource}
                        selectSource={selectSource}
                        setPage={setPage}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        handlePlatformsContinue={handlePlatformsContinue}
                    ></PlatformContainer>
                )}

                {page === 2 && (
                    <DimensionsContainer
                        isGraph={isGraph}
                        placeHolderDataSource={placeHolderDataSource}
                        selectDimension={selectDimension}
                        setPage={setPage}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        startNewQuery={startNewQuery}
                    ></DimensionsContainer>
                )}

                {page === 3 && (
                    <MetricsContainer
                        isGraph={isGraph}
                        placeHolderDataSource={placeHolderDataSource}
                        setPage={setPage}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        selectMetric={selectMetric}
                        selectCalcMetric={selectCalcMetric}
                    ></MetricsContainer>
                )}

                <ConversionsContainer
                    isGraph={isGraph}
                    placeHolderDataSource={placeHolderDataSource}
                    setPage={setPage}
                    page={page}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    selectCustomConversion={selectCustomConversion}
                ></ConversionsContainer>

                {page === 5 && (
                    <OverviewContainer
                        isGraph={isGraph}
                        placeHolderDataSource={placeHolderDataSource}
                        setPage={setPage}
                        page={page}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        prepUserQuery={prepUserQuery}
                        toggleHiddenDimension={toggleHiddenDimension}
                    ></OverviewContainer>
                )}

                {page === 6 && (
                    <div>
                        {[mainQueryStatus, mainTotalQueryStatus, ccQueryStatus, ccTotalQueryStatus].some(
                            (el) => el === 'RUNNING',
                        ) && !joinedData ? (
                            <div style={{ /* margin: 'auto' */ height: '80px' }}>
                                <ReactLoading className="loaderCentered" type="spinningBubbles" color={theme.publishButton} />
                            </div>
                        ) : (
                            <PreviewContainer
                                joinedData={joinedData}
                                isGraph={isGraph}
                                title={title}
                                setTitle={setTitle}
                                page={page}
                                ccData={ccData}
                                userMainQuery={userMainQuery}
                                userCCQuery={userCCQuery}
                                nextPage={nextPage}
                                previousPage={previousPage}
                                chartMetrics={chartMetrics}
                                setChartMetrics={(chartMetric: any) => setChartMetrics(chartMetric)}
                                componentID={element?.id as string}
                                element={element}
                                mainQueryID={mainQueryID}
                                ccQueryID={ccQueryID}
                                queryError={queryError}
                                setUserMainQuery={(q: any) => setUserMainQuery(q)}
                                setUserCCQuery={(q: any) => setUserCCQuery(q)}
                                setUserCCTotalQuery={(q: any) => setUserCCTotalQuery(q)}
                                setUserMainQueryTotal={(q: any) => setUserMainQueryTotal(q)}
                                placeholderList={placeholderList}
                                setStoredQueries={setStoredQueries || (() => { })}
                                setTablePlaceholderList={setTablePlaceholderList || (() => { })}
                                setPage={setPage}
                                setNewQuery={(newQuery: "OLD" | "NEW" | "BACK" | "APPLY") => setNewQuery(newQuery)}
                                updateTablePlaceholderDuplicate={updateTablePlaceholderDuplicate || (() => { })}
                                savePlaceholderToOrder2={savePlaceholderToOrder2 || (() => { })}
                                defaultSelected={defaultSelected}
                            // publishPlaceholderObject={publishPlaceholderObject}
                            ></PreviewContainer>
                        )}
                    </div>
                )}

                {page === 7 && (
                    <SavedContainer
                        joinedData={joinedData}
                        isGraph={isGraph}
                        title={title}
                        setTitle={setTitle}
                        page={page}
                        ccData={ccData}
                        userMainQuery={userMainQuery}
                        userCCQuery={userCCQuery}
                        chartMetrics={chartMetrics}
                        setChartMetrics={(chartMetric: any) => setChartMetrics(chartMetric)}
                        componentID={placeholderId as string}
                        previousPage={previousPage}
                        handleDuplicate={handleDuplicate}
                        mainQueryID={mainQueryID}
                        ccQueryID={ccQueryID}
                        removeItemFromList={removeItemFromList}
                        element={element}
                        defaultSelected={defaultSelected}
                    ></SavedContainer>
                )}
            </>
        </StyledTableContainer>
    );
};

export default PlaceholderContainer;
