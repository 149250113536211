import { useCampaignContext } from 'context/CampaignContext';
import { usePortalContext } from 'context/PortalContext';
import { useCampaignId } from 'customHooks/useParameters';
import { useEffect } from 'react';
import ReactLoading from 'react-loading';
import styled, { useTheme } from 'styled-components';

export const KPIContainerStyled = styled.div`
  padding: 0.6vw;
  display: grid;
  gap: 0.5vw;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  /* height: fit-content;
  min-height: 150px;
  max-height: 180px; */  //
  height: 182px; //USED TO BE OUT AND THREE UP ROWS IN
  padding-bottom: 1.5vw;
  background-color: ${({ theme }) => theme.placeholderBackground};
  border-radius: 7px 7px 0px 0px;
  border: 1px solid ${({ theme }) => theme.border};
   z-index: 1;
  border-bottom: 0px;
  .loader {
    margin: auto;
    grid-area: 1 /1 /-1/ -1;
  }
`;
const KPIholder = styled.div`
  width: 100%;
  height: 90%;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 7px;
  position: relative;
  font-weight: normal;
  display: flex;
  align-items: center;
  h1 {
    font-weight: normal;
    font-size: 0.8vw;
  }
  h2 {
    font-weight: normal;
    font-size: 0.6vw;
    font-family: 'fontMedium';
  }

  h4 {
    font-size: 1.1vw;
    color: ${({ theme }) => theme.brand};
    font-family: 'FontBold';
  }



  .valueFlex {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    // margin-top: 10px;
  }
  .plan {
    border-left: 3px solid #a8a8a8 ;
    padding-left: 10px;
    /* color: gray !important; */

    h2{
        color: #a8a8a8;
    }
  }
  .loader {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
`;

const TxtContainer = styled.div`
  padding: 0.5vw 1vw;
  white-space: nowrap;

  span {
    font-size: 14px;
    font-weight: normal;
    font-family: 'FontRegular';
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    width: fit-content;
    background-color: transparent;
    color: ${({ theme }) => theme.primaryGray};
    text-align: center;
    background-color: ${({ theme }) => theme.placeholderBackground};
    border: 1px solid ${({ theme }) => theme.primaryGray};
    border-radius: 7px;
    padding: 3px 8px;
    font-weight: normal;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
  .error {
    padding: 0.5vw 1vw;
    white-space: nowrap;
    h4 {
      font-size: 14px;
      font-weight: normal;
      font-family: 'FontBold';
      color: ${({ theme }) => theme.primaryGray};
    }
    h5 {
      font-size: 14px;
      font-weight: normal;
      font-family: 'FontRegular';
      color: ${({ theme }) => theme.primaryGray};
    }
  }
`;

const KPIContainer = () => {
  const {
    actualBudget,
    actualBudgetPublished,
    numberOfCampaigns,
    currentYearBudget,
    recommendedYearBudget,
    approvedBudget,
    fetchedPortalData,
    campaignList
  } = useCampaignContext();
  const theme = useTheme();
  const [getId] = useCampaignId();
  const { setBudgetBrief, setBudgetCTC, setBudgetApproved } = usePortalContext();


  useEffect(() => {

    if (getId === 'none') {
      setBudgetBrief(null);
      setBudgetCTC(null);
      setBudgetApproved(null);
    }
  }, [])



  return (
    <KPIContainerStyled>
      {actualBudget === -1 ? (
        <>
          <TxtContainer className="error">
            <div className="error">
              <h4>Something went wrong.</h4>
              <br />
              <h5>
                An error occured while processing your request. <br />
                Please try again or contact support.
              </h5>
            </div>
          </TxtContainer>
        </>
      ) : actualBudget === -2 ? (
        <>
          <TxtContainer className="loader">
            <ReactLoading type="spinningBubbles" color={theme.publishButton} />
          </TxtContainer>
        </>
      ) : (
        <>
          <KPIholder className="KPIBox">
            <TxtContainer>
              <h1>Briefed budget</h1>
              <div className="valueFlex">
                <h4 className="tooltip">
                  {Intl.NumberFormat('en-DK', { notation: 'compact' }).format(Number(currentYearBudget))}
                  <span className="tooltiptext">{Intl.NumberFormat('en-DK').format(Number(currentYearBudget))}</span>
                </h4>
              </div>
            </TxtContainer>
          </KPIholder>
          <KPIholder className="KPIBox">
            <TxtContainer>
              <h1>Actual media budget</h1>
              {
                // fetchedPortalData.length > 0 ? (
                <div className="valueFlex">
                  <h4 className="tooltip">
                    {Intl.NumberFormat('en-DK', { notation: 'compact' }).format(Number(actualBudgetPublished))}
                    <span className="tooltiptext">
                      {Intl.NumberFormat('en-DK').format(Number(actualBudgetPublished))}
                    </span>
                  </h4>
                  {campaignList && campaignList?.length > 0 && campaignList?.every(e => e?.authorized) && <div className="plan" style={{ marginLeft: '3%' }}>
                    <h2>Incl. unpublished</h2>
                    <h2 className="tooltip">
                      {actualBudget && Intl.NumberFormat('en-DK', { notation: 'compact' }).format(actualBudget)}
                      <span className="tooltiptext">
                        {actualBudget && Intl.NumberFormat('en-DK').format(actualBudget)}
                      </span>
                    </h2>
                  </div>}
                </div>
                // ) : (
                //   <div style={{ width: '22px', height: '23px' }}>
                //     <TxtContainer className="loader" style={{ left: "11%", top: "70%" }} >
                //       <ReactLoading type="spinningBubbles" color={theme.publishButton} width="25px" height="25px" />
                //     </TxtContainer>
                //   </div>

                // )
              }
            </TxtContainer>
          </KPIholder>
          <KPIholder className="KPIBox">
            <TxtContainer>
              <h1>Number of campaigns</h1>
              {
                // fetchedPortalData.length > 0 ? (
                <div className="valueFlex">
                  <h4 className="tooltip">
                    {numberOfCampaigns}
                    <span className="tooltiptext">{numberOfCampaigns}</span>
                  </h4>
                </div>
                // ) : 
                // (
                //   <div style={{ width: '22px', height: '23px' }}>
                //     <TxtContainer className="loader" style={{ left: "11%", top: "70%" }} >
                //       <ReactLoading type="spinningBubbles" color={theme.publishButton} width="25px" height="25px" />
                //     </TxtContainer>
                //   </div>

                // )
              }
            </TxtContainer>
          </KPIholder>
          <KPIholder className="KPIBox">
            <TxtContainer>
              <h1>Planned media budget</h1>
              <div className="valueFlex">
                <h4 className="tooltip">
                  {Intl.NumberFormat('en-DK', { notation: 'compact' }).format(Number(approvedBudget))}
                  <span className="tooltiptext">{Intl.NumberFormat('en-DK').format(Number(approvedBudget))}</span>
                </h4>
              </div>
            </TxtContainer>
          </KPIholder>
        </>
      )}
    </KPIContainerStyled>
  );
};

export default KPIContainer;
