import { DashboardTypes } from 'API';
import { useCampaignContext } from 'context/CampaignContext';
import { usePortalContext } from 'context/PortalContext';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import { useTab } from './useParameters';

export const useBackListener = () => {
  const location = useLocation();
  //   const [tab, setTab] = useTab();

  const { dashType, setDashType } = useCampaignContext();
  const { copyOfTab } = usePortalContext()
  //TODO NEW DASHBOARD
  const pathToDashType: Record<string, DashboardTypes> = {
    '/reporting/onlineportal': DashboardTypes.DIGITAL,
    '/reporting/tvportal': DashboardTypes.TV,
    '/reporting/radioportal': DashboardTypes.RADIO,
    '/reporting/printportal': DashboardTypes.PRINT,
    '/reporting/oohportal': DashboardTypes.OOH,
    '/reporting/portal': DashboardTypes.FLOWCHART,
    '/reporting/cinemaportal': DashboardTypes.CINEMA,
    '/reporting': DashboardTypes.HOME,
    '/reporting/': DashboardTypes.HOME,
    '/reporting/insourcedataportal': DashboardTypes.INSOURCE_DATA,

  };

  useEffect(() => {
    const dashType2 = pathToDashType[location.pathname.toLowerCase()];

    if (dashType2) {
      setDashType(dashType2);
    }
  }, [location]);
};
