import { TxtContainer } from 'components/Main/Main.styles';
import { useContext, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import KPIContainer from './KPIContainer';
import KPICharts from './KPICharts';
import ReportsLinks from './ReportsLinks';
import { useCampaignContext } from 'context/CampaignContext';
import { DarkButton } from 'components/style/Global';
import Comments from 'components/Comments/Comments';
import ReactLoading from 'react-loading';
import { PublishContext } from 'context/PublishContext';
import { colorsForIcon } from '../Brief/UploadFile/2.2.UploadFile';
import { QueryInfo } from '../Brief/Brief';
import FileList from '../Brief/UploadFile/3.3.FilesList';
import { UploadFileContainer } from '../Brief/style/BriefInputStyle';
import { useSearchParams } from 'react-router-dom';
import { API, Auth } from 'aws-amplify';
import { getStoredQuery } from 'graphql/queries';
import { DeleteStoredQueryMutation, GetStoredQueryQuery, UserQuery } from 'API';
import { getFilesFromS3 } from '../Brief/UploadFile/getFilesfromS3';
import APIContext from 'context/APIContext';
import { deleteStoredQuery } from 'graphql/mutations';
import { useClientContext } from 'context/ClientContext';
import ClientStatementsContext from 'context/ClientStatementsContext';
import { usePortalContext } from 'context/PortalContext';

type Props = {
  approvedBudget: number | null;
};

const SummaryContainer = styled.div`
  margin-top: 30px;
  
  .buble .bubletext {
    visibility: hidden;
    width: fit-content;
    background-color: transparent;
    color: ${({ theme }) => theme.primaryGray};
    text-align: center;
    background-color: ${({ theme }) => theme.placeholderBackground};
    border: 1px solid ${({ theme }) => theme.primaryGray};
    border-radius: 7px;
    padding: 3px 8px;
    font-weight: normal;

    /* Position the tooltip */
    position: absolute;
    right: 5vw;
    top: 15vw;
    z-index: 1;
  }

  .buble:hover .bubletext {
    visibility: visible;
  }
`;
const SubContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 7px;
  width: 100%;
  padding: 30px 40px;
  margin-bottom: 0.5vw;

  h1 {
    font-size: 1vw;
    font-family: 'fontBold';
    font-weight: normal;
  }
  .texAreaContainer {
    padding: 1vw 0 0vw 0.3vw;
  }
  textarea {
    font-size: 12px;
    font-family: 'fontRegular';
    padding: 8px;
    border-radius: 7px;
    border: 1px solid ${({ theme }) => theme.border};
    color: ${({ theme }) => theme.primaryGray};
    background-color: ${({ theme }) => theme.background};
    resize: none;
    min-height: 100px;
    width: 100%;
  }
  .error {
    padding: 0.5vw 1vw;
    white-space: nowrap;
    h4 {
      font-size: 14px;
      font-weight: normal;
      font-family: 'FontBold';
      color: ${({ theme }) => theme.primaryGray};
      margin-bottom: 1vw;
    }
    h5 {
      font-size: 14px;
      font-weight: normal;
      font-family: 'FontRegular';
      color: ${({ theme }) => theme.primaryGray};
    }
  }
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const PublishedButton = styled.div`
  position: absolute;
  right: 100px;
  border: 1px solid lightgray;
  padding: 7px;
  border-radius: 7px;
  padding-left: 12px;
  padding-right: 12px;
  color: #807f83;
  cursor: pointer;

  &:hover {
    border-color: black;
  }
`;

const Summary = (props: Props) => {
  const { approvedBudget } = props;
  const { activeCampaign, actualBudget } = useCampaignContext();
  const { handlePublishSummary, campaignOverFlowqueryStatus, unpublishCampaign } = useContext(PublishContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const [toggleComment, setToggleComment] = useState(true);
  const [selectedDocument, setSelectedDocument] = useState([] as QueryInfo[]);

  const colorArray = colorsForIcon;
  const { getEnvironment } = useContext(APIContext);
  const [uploadedFiles, setUploadedFiles] = useState<QueryInfo[]>([]);
  const { userAccess } = useClientContext();
  const { statements } = useContext(ClientStatementsContext);
  const { setCopyOfTab } = usePortalContext()


  // console.log('what is this in the end ??', userAccess);
  const isClient = userAccess ? userAccess.readAccess : true;
  const { fullAccess = false, readAccess = false, noAccess = false } = userAccess || {};
  setCopyOfTab('summary')

  async function fetchStoredQueriesID(position: string): Promise<File> {
    try {
      const response = (await API.graphql({
        query: getStoredQuery,
        variables: {
          campaign_id: activeCampaign?.campaignId?.toString(),
          position: position,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: GetStoredQueryQuery };
      const responseID = response.data.getStoredQuery;

      const file = await getFilesFromS3(
        responseID as UserQuery,
        activeCampaign?.ClientCode as string,
        activeCampaign?.ClientCountry as string,
        getEnvironment,
      );

      if (file) {
        setUploadedFiles((prev) =>
          prev.map((e) => {
            if (e.id === responseID?.query_id) {
              return { ...e, file: file };
            } else {
              return e;
            }
          }),
        );
      }

      return file as File;
    } catch (e) {
      console.log(`ERROR WAS THIS: ${e}`);
      return {} as File;
    }
  }

  const openFile = async (file2: QueryInfo) => {
    const file = file2.file ?? (await fetchStoredQueriesID(file2.position));
    // const file = file2.file as File;
    const url = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
  };

  const openSelectedDocumentInNewTab = async () => {
    // console.log('hshshshshshshssh', selectedDocument);
    const file = await fetchStoredQueriesID(selectedDocument[0].position);
    // const file = file2.file as File;
    const url = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
  };

  async function removeFromS3(position: string) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const deleteStoredQ = (await API.graphql({
        query: deleteStoredQuery,
        variables: {
          campaign_id: activeCampaign?.campaignId?.toString(),
          Environment: getEnvironment,
          placeholder: position,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: DeleteStoredQueryMutation };
    } catch (e) {
      console.log('ERROR  DELETING QUERY', e);
    }
  }

  const removeFile = (queryInfo: QueryInfo) => {
    setSelectedDocument(selectedDocument.filter((f) => f.id !== queryInfo.id));
    removeFromS3(queryInfo.position);
  };

  // console.log("this is the first access I need to check", activeCampaign?.authorized )

  const isUserAuthorized = activeCampaign?.authorized;
  const Create = statements?.Queries?.CREATE;

  const theme = useTheme();
  // console.log('this is the campign', activeCampaign);
  // const subs = activeCampaign?.subcampaign
  // const dashes = activeCampaign?.dashboardType
  // const checkMinusOne = dashes?.map(dash => {
  //   if (subs) {

  //     if (dash === "DIGITAL") {
  //       if (subs[-1].published.DIGITAL === true) {
  //         return true
  //       } else {
  //         return false
  //       }
  //     }
  //     if (dash === "PRINT") {
  //       if (subs[-1].published.PRINT === true) {
  //         return true
  //       } else {
  //         return false
  //       }
  //     }
  //     if (dash === "OOH") {
  //       if (subs[-1].published.OOH === true) {
  //         return true
  //       } else {
  //         return false
  //       }
  //     }
  //     if (dash === "CINEMA") {
  //       if (subs[-1].published.CINEMA === true) {
  //         return true
  //       } else {
  //         return false
  //       }
  //     }


  //   }
  // })




  // const checkIfPublished = () => {
  //   const arr: any = [];
  //   if (activeCampaign?.subcampaign) {
  //     for (const [key, value] of Object.entries(activeCampaign.subcampaign)) {
  //       const publishedobj = (value as any).published;
  //       const published = Object.values(publishedobj).map(e => e === true);
  //       arr.push(published.some(e => e === true ? true : false))
  //     }
  //   }
  //   return arr/* .some((e: boolean) => e === true); */
  // };


  // const k = checkIfPublished()
  // const allIsPublished = !checkMinusOne?.some(e => e === false) && k.every((e: boolean) => e === true) ? true : false












  const availableDashboards = activeCampaign?.dashboardType.filter(a => a !== "INSOURCE_DATA").map(e => {
    const dash = e as string
    const subs = activeCampaign?.subcampaign
    const arr = []
    for (const [key, value] of Object.entries(subs)) {
      const publishedobj = (value as any).published;
      arr.push(publishedobj[dash])
    }
    return {
      [dash]: arr.some(e => e === true)
    }
  })

  const isAllPublished = availableDashboards && availableDashboards.every(e => Object.values(e)[0] === true)







  const [bubleVisible, setBubleVisible] = useState(false)
  const handleBuble = () => {
    setBubleVisible(!bubleVisible)
    setTimeout(() => {
      setBubleVisible(false)
    }, 3000);
  }

  // console.log("actove campaign", activeCampaign)
  return (
    <SummaryContainer>

      {(activeCampaign?.authorized || (!activeCampaign?.authorized && activeCampaign?.status === "CAMPAIGN_PUBLISHED")) ?
        <SubContainer>

          <>
            {campaignOverFlowqueryStatus !== 'RUNNING' ? (
              <>
                <div style={{ display: 'flex' }}>
                  <h1>KEY FIGURES</h1>

                  {activeCampaign && activeCampaign?.authorized &&
                    <>
                      {activeCampaign?.status === 'CAMPAIGN_PUBLISHED' ? (
                        <PublishedButton onClick={() => unpublishCampaign()}>Published</PublishedButton>
                      ) : (
                        <>
                          {isAllPublished ?
                            <DarkButton onClick={() => handlePublishSummary()} style={{ position: 'absolute', right: '100px' }}>
                              Publish
                            </DarkButton> :
                            <>
                              <button onClick={handleBuble} style={{ position: 'absolute', right: '100px' }}>Publish</button>
                              <span className="buble">
                                <span className="bubletext" style={{ visibility: bubleVisible ? "visible" : "hidden" }}>You can not publish the Summary page until all reports have been published.</span>
                              </span>
                            </>

                          }

                        </>


                      )}
                    </>
                  }
                </div>
                {actualBudget === -1 ? (
                  <div className="error">
                    <h4>Something went wrong.</h4>
                    <br />
                    <h5>
                      An error occured while processing your request. <br />
                      Please try again or contact support.
                    </h5>
                  </div>
                ) : actualBudget === -2 ? (
                  <>
                    <TxtContainer className="loader">
                      <ReactLoading type="spinningBubbles" color={theme.publishButton} />
                    </TxtContainer>
                  </>
                ) : (
                  <div className="KPIContainer">
                    {activeCampaign && <KPIContainer approvedBudget={approvedBudget}></KPIContainer>}
                    <KPICharts></KPICharts>
                  </div>
                )}
                <h1>KEY INTAKES AND NOTES</h1>
                <Comments
                  header={false}
                  toggleComment={toggleComment}
                  componentID="overall"
                  setToggleComment={(toggleComment: boolean) => setToggleComment(toggleComment)}
                ></Comments>
              </>
            ) : (
              <div className="loader">
                <ReactLoading type="spinningBubbles" color={theme.publishButton} />
              </div>
            )}
          </>
        </SubContainer> :
        <SubContainer>
          <h1>CAMPAIGN SUMMARY</h1>
          <div className="error">
            <h4>There is no campaign summary available</h4>
            <br />
            <h5>
              The campaign is ongoing, or the reports are not yet ready.<br />
              Please reach out to your agency contact person.
            </h5>
          </div>
        </SubContainer>
      }

      <SubContainer>
        <h1>INDIVIDUAL REPORTS</h1>
        {activeCampaign && (
          <ReportsLinks
            document={selectedDocument}
            setDocument={setSelectedDocument}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            isClient={isClient}
            openSelectedDocumentInNewTab={openSelectedDocumentInNewTab}
            fullAccess={fullAccess}
            readAccess={readAccess}
            noAccess={noAccess}
            isUserAuthorized={isUserAuthorized}
            onFileOpen={openFile}
          ></ReportsLinks>
        )}
        <div>
          <>
            {isUserAuthorized && Create ? (
              <UploadFileContainer>
                <div className="uploadedFilesRightContianer" style={{ marginLeft: '0px' }}>
                  {selectedDocument.length > 0 && (
                    <FileList
                      files={selectedDocument}
                      onFileOpen={openFile}
                      onFileRemove={removeFile}
                      colorsForIcon={colorArray}
                    />
                  )}
                </div>
              </UploadFileContainer>
            ) : (
              <></>
            )}
          </>
        </div>
      </SubContainer>
    </SummaryContainer >
  );
};

export default Summary;
