import { Amplify } from 'aws-amplify';
import { darkTheme, lightTheme } from 'components/style/Theme';
import { useContext, useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Aside from 'components/Aside/Aside';
import Header from 'components/Header/Header';
import GlobalStyle from 'components/style/Global';
import AuthContext from '../src/context/AuthContext';

// import TVmain from 'components/TV/TVmain';
// import OOHMain from 'components/OOH/OOHMain';
// import PrintMain from 'components/Print/PrintMain';
// import RadioMain from 'components/Radio/RadionMain';

import RadioMain from 'components/newSetup/RADIO/RadioMain';
import TVmain from 'components/newSetup/TV/TVMain';
import PrintMain from 'components/newSetup/OOH&PRINT/PrintMain';
import OOHMain from 'components/newSetup/OOH&PRINT/OOHMain';

import Wrapper, { WrapperContainer } from 'components/Wrapper';
import { useCampaignContext } from 'context/CampaignContext';
import DrawerModal from 'components/NewPortal/DrawerModal';
import { usePortalContext } from 'context/PortalContext';
import { DashboardTypes } from 'API';
import MainStartPage from 'components/NewPortal/Main/Main_StartPage';
// import { HeaderAndMain, HeaderAndMainType } from 'components/Portal/LoginPage';
import BackgroundImg from './assets/introImg/BackgroundImg.jpg';

import { IData } from 'customHooks/useDataQuery';

import Main from 'components/newSetup/Main';
import HomePage2 from 'components/HomePage/HomePage2';
import InputContext from 'context/InputContext';
import { HeadModul } from 'components/Main/table/TableStyles';
import { useTab } from 'customHooks/useParameters';
import CinemaMain from 'components/newSetup/CINEMA/CinemaMain';

import { matchRoutes, useLocation } from 'react-router-dom';
import { useBackListener } from 'customHooks/useTestChangeDashboardType';

// import Main from 'components/Main/Main';
const {
  REACT_APP_IDENTITY_POOL_ID,
  REACT_APP_AWS_REGION,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_FILES_S3_BUCKET,
  REACT_APP_USER_WEB_CLIENT_ID,
  REACT_APP_REDIRECT_SIGN_IN,
  REACT_APP_REDIRECT_SIGN_OUT,
  REACT_APP_APPSYNC_API_KEY,
  REACT_APP_GRAPHQL,
} = process.env;

const amplifyConf = {
  aws_appsync_graphqlEndpoint: REACT_APP_GRAPHQL,
  aws_project_region: 'eu-west-1',

  aws_appsync_region: REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: REACT_APP_APPSYNC_API_KEY,
  // graphql_headers: async () => ({ 'Authorization': (await Auth.currentSession()).getAccessToken().getJwtToken() }),
  aws_user_files_s3_bucket: REACT_APP_USER_FILES_S3_BUCKET,
  aws_user_files_s3_bucket_region: REACT_APP_AWS_REGION,
  Auth: {
    identityPoolId: REACT_APP_IDENTITY_POOL_ID,
    region: REACT_APP_AWS_REGION,
    userPoolId: REACT_APP_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_USER_WEB_CLIENT_ID,
    mandatorySignIn: false,
    oauth: {
      domain: 'authentication.auth.eu-west-1.amazoncognito.com',
      scope: ['openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: REACT_APP_REDIRECT_SIGN_IN,
      redirectSignOut: REACT_APP_REDIRECT_SIGN_OUT,
      responseType: 'token',
    },
    Storage: {
      AWSS3: {
        bucket: REACT_APP_USER_FILES_S3_BUCKET,
        region: REACT_APP_AWS_REGION,
        identityPoolId: REACT_APP_IDENTITY_POOL_ID,
        userPoolId: REACT_APP_USER_POOL_ID,
      },
    },
  },
};
Amplify.configure(amplifyConf);

export type HeaderAndMainType = {
  dashType: DashboardTypes;
};

const HeaderAndMain = styled.div<HeaderAndMainType>`
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  top: 0;
  margin-left: 4.5vw;
  width: ${({ dashType }) => (dashType !== DashboardTypes.HOME ? 'calc(100vw - 4.5vw)' : '100vw')};
  /* width: calc(100vw - 4.5vw); */
  /* background-color: red; */
  padding: 0;

  .welcome {
    position: relative;
    height: 90vh;
  }
  .intro {
    position: absolute;
    height: 90%;
    width: 50%;
    top: 25%;
    padding-left: 5vw;
    padding-right: 10vw;
    h2 {
      font-weight: 200;
      color: ${({ theme }) => theme.primaryGray};
    }
  }
  .image {
    background-color: ${({ theme }) => theme.placeholderBackground};
    position: absolute;
    width: 70vw;
    height: 70vw;
    right: -10%;
    top: 10%;
    border-radius: 50%;
    padding: 12vw 0 0 12vw;
  }
  .loaders {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 100px);
    padding-bottom: 1vh;
    margin-top: 1vh;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const MainContianerPage = styled.div<HeaderAndMainType>`
  /* background: ${({ dashType, theme }) =>
    dashType !== DashboardTypes.HOME
      ? theme.background
      : `linear-gradient(to right, rgba(26, 27, 30, 0.85), rgba(39, 41, 42, 0.75)), url(${BackgroundImg})`}; */
  background: ${({ dashType, theme }) => (dashType !== DashboardTypes.HOME ? theme.background : '')};
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
`;

export const App = () => {
  const { isAuthorized } = useContext(AuthContext);
  const { dashType } = useCampaignContext();
  const { drawerIsOpen } = usePortalContext();
  const { capture } = useContext(InputContext);
  const [hidden, setHidden] = useState<Boolean | null>(null);
  const [theme, setTheme] = useState(lightTheme);
  useBackListener();

  //THEME TOGGLER LOGIC
  localStorage.getItem('myTheme') === null
    ? window.localStorage.setItem('myTheme', JSON.stringify(lightTheme))
    : localStorage.getItem('myTheme');

  const themeToggler = (args: any) => {
    window.localStorage.getItem('myTheme') === JSON.stringify(lightTheme)
      ? window.localStorage.setItem('myTheme', JSON.stringify(darkTheme))
      : window.localStorage.setItem('myTheme', JSON.stringify(lightTheme));
    setTheme(JSON.parse(window.localStorage.getItem('myTheme')!));
  };

  useEffect(() => {
    if (isAuthorized === null) return;
    isAuthorized ? setHidden(false) : setHidden(true);
  }, [isAuthorized]);

  const changeHidden = () => {
    setHidden(!hidden);
  };
  return (
    <ThemeProvider theme={theme}>
      {hidden !== null && <Wrapper changeHidden={changeHidden} hidden={hidden}></Wrapper>}
      {capture && (
        <WrapperContainer>
          <HeadModul className="head">
            <section style={{ padding: '20px' }}>
              <p>
                Loading
                <span className="loadingDot">.</span>
                <span className="loadingDot">.</span>
                <span className="loadingDot">.</span>
              </p>
              <h2>Your download is being prepared</h2>
            </section>
          </HeadModul>
        </WrapperContainer>
      )}
      <GlobalStyle />
      {dashType === null ? (
        <></>
      ) : (
        <MainContianerPage dashType={dashType}>
          <HeaderAndMain dashType={dashType}>
            {dashType !== DashboardTypes.HOME && <Header></Header>}
            {dashType === 'DIGITAL' && <Main></Main>}
            {dashType === 'TV' && <TVmain></TVmain>}
            {dashType === 'RADIO' && <RadioMain></RadioMain>}
            {dashType === 'PRINT' && <PrintMain></PrintMain>}
            {dashType === 'OOH' && <OOHMain></OOHMain>}
            {dashType === 'FLOWCHART' && <MainStartPage></MainStartPage>}
            {dashType === 'CINEMA' && <CinemaMain></CinemaMain>}
            {dashType === "INSOURCE_DATA" && <Main></Main>}
          </HeaderAndMain>
          <></>

          {drawerIsOpen && <DrawerModal />}
          {dashType !== DashboardTypes.HOME && <Aside themeToggler={themeToggler} theme={theme}></Aside>}
          {dashType === DashboardTypes.HOME && <HomePage2 dashType={dashType} />}
        </MainContianerPage>
      )}
    </ThemeProvider>
  );
};
