import { TxtContainer } from 'components/Main/Main.styles';
import { ACdetails, useCampaignContext } from 'context/CampaignContext';
import styled, { useTheme } from 'styled-components';
import CustomKPI from './CustomKPI';
import { usePortalContext } from 'context/PortalContext';
import { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { updateCampaignFee, updatePlannedMediaBudget } from 'graphql/mutations';
import { GetPublishedOutputQuery, UpdateCampaignFeeMutation, UpdateCampaignFeeMutationVariables, UpdatePlannedMediaBudgetMutation } from 'API';
import { VscEdit } from 'react-icons/vsc';
import KPIFee from './KPIFee';
import ReactLoading from 'react-loading';
import { IconButton } from 'components/style/Global';
import { set } from 'lodash';

type Props = {
  approvedBudget: number | null;
};

const KPIContainerStyled = styled.div`
  display: flex;
  gap: 0.6vw;
  padding: 1vw 0 0vw 0.3vw;
`;

export const KPIholder = styled.div`
  //width: 100%;
  width: 20%;
  /* height: 90%; */
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 7px;
  position: relative;
  font-weight: normal;
  display: flex;
  align-items: space-around;
  h1 {
    font-weight: normal;
    font-size: 0.6vw;
  }
  h2 {
    font-weight: normal;
    font-size: 0.6vw;
    font-family: 'fontMedium';
  }

  h4 {
    font-size: 1.4vw;
    color: ${({ theme }) => theme.brand};
    font-family: 'FontBold';
  }
.testtt{
display:none;
}  

  .valueFlex {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    // margin-top: 10px;
  }
  .plan {
    border-left: 3px solid ${({ theme }) => theme.secondaryGray};
    padding-left: 10px;
  }
  .loader {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
`;

const KPIContainer = (props: Props) => {
  const { approvedBudget } = props;
  const { campaignActualBudget, activeCampaign, campaignList, setPermanentCampaignList, permanentCampaignList } = useCampaignContext();
  const { fetchedPortalData } = useCampaignContext();
  const { budgetApproved, setBudgetApproved } = usePortalContext();
  const theme = useTheme();
  console.log("MAMATIJEDROCA", budgetApproved, activeCampaign?.approved_budget)
  const budgetValue = budgetApproved !== null ? budgetApproved.budget : activeCampaign?.approved_budget;

  // console.log('This is the fetchedPortalData', activeCampaign);

  const [actualFee, setActualFee] = useState(false);
  const [actualFeeValue, setActualFeeValue] = useState(
    activeCampaign?.actual_fee !== null ? activeCampaign?.actual_fee : ('' as string),
  );

  const [plannedFee, setPlannedFee] = useState(false);
  const [plannedFeeValue, setPlannedFeeValue] = useState(
    activeCampaign?.planned_fee !== null ? activeCampaign?.planned_fee : ('' as string),
  );

  const plannedFeeUpdated = campaignActualBudget + Number(actualFeeValue)

  const [error, setError] = useState('' as string);

  const onSaveFee = async (type: string, value: number) => {
    // const ActualFee = type === 'Actual Fee' ? value : null;
    // const PlannedFee = type === 'Planned Fee' ? value : null;

    const ActualFee = type === 'Other Expenses' ? value : null;
    const PlannedFee = plannedFeeUpdated;

    try {
      const response = (await API.graphql({
        query: updateCampaignFee,
        variables: {
          CampaignId: activeCampaign?.campaignId,
          ActualFee: ActualFee,
          PlannedFee: PlannedFee,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: UpdateCampaignFeeMutation };

      const data = response.data;
      const updateCampaignFee2 = data.updateCampaignFee;

      if (updateCampaignFee2?.StatusCode === 200) {
        const campaign = campaignList?.find((campaign: any) => campaign.campaignId === activeCampaign?.campaignId);
        const newCampaign = {
          ...campaign,
          actual_fee: ActualFee !== null ? ActualFee : actualFeeValue,
          planned_fee: PlannedFee !== null ? PlannedFee : plannedFeeValue,
        } as ACdetails;

        const newCampaignList = campaignList?.map((campaign: ACdetails) => {
          if (campaign?.campaignId === activeCampaign?.campaignId) {
            return newCampaign;
          } else {
            return campaign;
          }
        }) as ACdetails[];

        setPermanentCampaignList(newCampaignList);
      } else {
        setError('Something went wrong with updating the Fee. Please try again');
      }
    } catch (e) {
      setError('Something went wrong with updating the Fee. Please try again');
      // console.log('this is wahttttt ???', e);
      console.error(e);
    }
  };

  const handleSaveFee = (type: string) => {
    let number;

    if (type === 'Other Expenses') {
      number = Number(actualFeeValue);
      setActualFee(!actualFee);
    } else {
      number = Number(plannedFeeValue);
      setPlannedFee(!plannedFee);
    }

    void onSaveFee(type, number);
  };

  const handleCancel = (type: string) => {
    if (type === 'Other Expenses') {
      setActualFee(!actualFee);
      activeCampaign !== null && activeCampaign?.actual_fee && setActualFeeValue(activeCampaign?.actual_fee)
      activeCampaign !== null && activeCampaign?.planned_fee && setPlannedFeeValue(activeCampaign?.planned_fee)
    } else {
      setPlannedFee(!plannedFee);
    }
  };

  const [editPlannedMediaBudget, setEditPlannedMediaBudget] = useState(false)
  const [PMBvalue, setPMBvalue] = useState('')
  const savePlannedMediaBudget = async () => {
    try {
      const response = (await API.graphql({
        query: updatePlannedMediaBudget,
        variables: {
          campaignId: activeCampaign?.campaignId,
          budget: Number(PMBvalue)
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: UpdatePlannedMediaBudgetMutation }

      setEditPlannedMediaBudget(false)
      setBudgetApproved({ budget: Number(PMBvalue) })


      const updatedCampaignList = permanentCampaignList?.map((el) => {
        if (el?.campaignId === Number(activeCampaign?.campaignId)) {
          return {
            ...el,
            approved_budget: Number(PMBvalue),
          };
        }
        return el;
      }) as ACdetails[];

      setPermanentCampaignList(updatedCampaignList);


    } catch (error) {
      console.error('error', error)
    }
  }


  return (
    <KPIContainerStyled>
      <KPIholder className="KPIBox">
        <TxtContainer >
          <h1 style={{ fontSize: '0.6vw' }}>Planned Media Budget</h1>


          {!editPlannedMediaBudget ? <div className="valueFlex" onClick={() => activeCampaign?.authorized && setEditPlannedMediaBudget(!editPlannedMediaBudget)}>
            <h4 className="tooltip">
              {Intl.NumberFormat('en-DK', { notation: 'compact', maximumFractionDigits: 2 }).format(
                Number(budgetValue),
              )}
              <span className="tooltiptext">
                {Intl.NumberFormat('en-DK', { maximumFractionDigits: 0 }).format(Number(budgetValue))}
              </span>
            </h4>
          </div> :
            <input
              type="number"
              defaultValue={Number(budgetValue)}
              onChange={(e: any) => setPMBvalue(e.target.value)}
            />}
          {editPlannedMediaBudget && (
            <div style={{ display: 'flex', justifyContent: "space-between" }}>
              <IconButton style={{ margin: '0px', padding: '0px' }} type="button" onClick={() => savePlannedMediaBudget()}>
                Save
              </IconButton>
              <IconButton style={{ margin: '0px', padding: '0px' }} type="button" onClick={() => {
                setEditPlannedMediaBudget(false);

              }}>
                Cancel
              </IconButton>
            </div>
          )}
        </TxtContainer>
      </KPIholder>

      <KPIholder className="KPIBox">
        {/* {fetchedPortalData.length > 0 ? ( */}
        <TxtContainer>
          <h1 style={{ fontSize: '0.6vw' }}>Actual Media Budget</h1>
          {((activeCampaign?.authorized && fetchedPortalData.length > 0) || !activeCampaign?.authorized) ? (
            <div className="valueFlex">
              <h4 className="tooltip">
                {Intl.NumberFormat('en-DK', { notation: 'compact', maximumFractionDigits: 2 }).format(
                  campaignActualBudget,
                )}
                <span className="tooltiptext">
                  {Intl.NumberFormat('en-DK', { maximumFractionDigits: 0 }).format(campaignActualBudget)}
                </span>
              </h4>
            </div>
          ) : (
            <>
              <div style={{ width: '20px', height: '30px' }}>
                <TxtContainer className="loader" style={{ left: '20%', top: '60%' }}>
                  <ReactLoading type="spinningBubbles" color={theme.publishButton} width="25px" height="25px" />
                </TxtContainer>
              </div>
            </>
          )}
        </TxtContainer>
      </KPIholder>

      <KPIFee
        fee={actualFee}
        setFee={setActualFee}
        feeValue={actualFeeValue}
        setFeeValue={setActualFeeValue}
        handleSave={handleSaveFee}
        error={error}
        title="Other Expenses"
        handleCancel={handleCancel}
      />

      {/* <KPIFee
        fee={plannedFee}
        setFee={setPlannedFee}
        feeValue={plannedFeeValue}
        setFeeValue={setPlannedFeeValue}
        handleSave={handleSaveFee}
        error={error}
        title="Planned Fee"
        handleCancel={handleCancel}
      /> */}

      <KPIholder className="KPIBox">
        {/* {fetchedPortalData.length > 0 ? ( */}
        <TxtContainer>
          <h1 style={{ fontSize: '0.6vw' }}>Actual CTC Budget</h1>
          {((activeCampaign?.authorized && fetchedPortalData.length > 0) || !activeCampaign?.authorized) ? (
            <div className="valueFlex">
              <h4 className="tooltip">
                {Intl.NumberFormat('en-DK', { notation: 'compact', maximumFractionDigits: 2 }).format(
                  plannedFeeUpdated
                )}
                <span className="tooltiptext">
                  {Intl.NumberFormat('en-DK', { maximumFractionDigits: 0 }).format(plannedFeeUpdated)}
                </span>
              </h4>
            </div>
          ) : (
            <>
              <div style={{ width: '20px', height: '30px' }}>
                <TxtContainer className="loader" style={{ left: '20%', top: '60%' }}>
                  <ReactLoading type="spinningBubbles" color={theme.publishButton} width="25px" height="25px" />
                </TxtContainer>
              </div>
            </>
          )}
        </TxtContainer>
      </KPIholder>

      <CustomKPI componentID={'VALUE1.3'}></CustomKPI>
      <CustomKPI componentID={'VALUE1.4'}></CustomKPI>
    </KPIContainerStyled>
  );
};

export default KPIContainer;
