import {
  campaignResponse,
  countryEnum,
  DashboardTypes,
  datatype,
  filtertype,
  FlowchartCampaignStatus,
  GetPublishedOutputQuery,
  Placeholder,
  PlaceholderObject,
  publishedType,
  PublishOutputLocation_v2Mutation,
  PublishOutputLocationMutation,
  queryInput,
  UnPublishCampaignMutation,
  UnPublishMutation,
} from 'API';
import { API } from 'aws-amplify';
import { publishOutputLocation_v2, unPublish, unPublishCampaign } from 'graphql/mutations';
import { getPublishedOutput } from 'graphql/queries';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import APIContext from './APIContext';
import { ACdetails, CampaignContext, useCampaignContext } from './CampaignContext';
import { useDataQuery } from 'customHooks/useDataQuery';
import { useUpdateCampaignStatus } from 'components/NewPortal/Main/Brief/CustomHook/useUpdateCampaignStatus';
import { checkIfPublished } from '../components/NewPortal/Main/Summary/ReportsLinks';
import { useTemplateContext } from './TemplateContext';
import { useFetchPlanData } from 'components/NewPortal/Main/Plan/useFetchPlanData';
import { useClientContext } from './ClientContext';
import { useCheckRollbackStatus } from 'customHooks/useCheckRollbackStatus';

export const PublishContext = createContext({
  publishCampaign: () => { },
  unpublishCampaign: () => { },
  updateOLArray: (args: any) => { },
  setPublishedData: (args: any) => { },
  publishedData: [] as any[],
  isPublished: false,
  handlePublishSummary: () => { },
  campaignOverFlowqueryStatus: 'NOT STARTED' as 'RUNNING' | 'FAILED' | 'DONE' | 'NOT STARTED',
  removePlaceholderfromPublishArray: (args: any) => { },
  placeholders: {
    DIGITAL: [] as any[],
    TV: [] as any[],
    RADIO: [] as any[],
    OOH: [] as any[],
    PRINT: [] as any[],
    CINEMA: [] as any[],
  }
});

export const PublishContextProvider = (props: any) => {
  const { activeCampaign, checkDashPublished, dashType, activeSubCampaign, overflowKPIquery } = useCampaignContext();
  const { activeOrganization, userCanDoInsource } = useClientContext()
  const { getEnvironment } = useContext(APIContext);
  const [publishedData, setPublishedData] = useState<Placeholder[]>([]);
  const [isPublished, setIsPublished] = useState<boolean>(false);
  const [campaignOverflowKPIquery, setCampaignOverflowKPIquery] = useState<queryInput>();
  const [placeholders, setPlaceholders] = useState({
    DIGITAL: [] as any[],
    TV: [] as any[],
    RADIO: [] as any[],
    OOH: [] as any[],
    PRINT: [] as any[],
    CINEMA: [] as any[],
  });
  const { fetchedPlaceholders } = useTemplateContext();
  const [UpdateCampaign] = useUpdateCampaignStatus();
  const { setPermanentCampaignList, permanentCampaignList } = useCampaignContext();
  const [rollbackStatus] = useCheckRollbackStatus(activeCampaign)

  let activeSubCampaignObject:
    | {
      campaignId: string;
      subCampaignId: string;
      published: publishedType;
      __typename: string;
    }
    | undefined;

  if (activeSubCampaign) {
    const subcampaignId = activeSubCampaign.subcampaignId as number;
    activeSubCampaignObject = activeCampaign?.subcampaign[subcampaignId];
  }

  useEffect(() => {
    if (activeSubCampaignObject) {
      dashType !== null && setIsPublished(checkDashPublished(activeSubCampaignObject, dashType === "INSOURCE_DATA" ? DashboardTypes.DIGITAL : dashType));
    } else {
      setIsPublished(false);
    }
  }, [activeSubCampaignObject, dashType, activeCampaign]);

  const removePlaceholderfromPublishArray = (position: string) => {
    const objcopy = placeholders;
    if (position.includes('TV')) {
      objcopy.TV = objcopy.TV.filter((e) => e.position !== position);
    } else if (position.includes('Radio')) {
      objcopy.RADIO = objcopy.RADIO.filter((e) => e.position !== position);
    } else if (position.includes('Print')) {
      objcopy.PRINT = objcopy.PRINT.filter((e) => e.position !== position);
    } else if (position.includes('OOH')) {
      objcopy.OOH = objcopy.OOH.filter((e) => e.position !== position);
    } else if (position.includes('CINEMA')) {
      objcopy.CINEMA = objcopy.CINEMA.filter((e) => e.position !== position);
    } else {
      objcopy.DIGITAL = objcopy.DIGITAL.filter((e) => e.position !== position);
    }
    setPlaceholders(objcopy);
  }

  const updateOLArray = (locationObject: any) => {

    if (locationObject.position.includes('TV')) {
      if (placeholders.TV.some((e) => e.position === locationObject.position)) {
        placeholders.TV.map((e: any) => {
          if (e.position === locationObject.position) {
            return locationObject;
          }
          return e;
        });
      } else {
        const objcopy = placeholders;
        objcopy.TV.push(locationObject);
        setPlaceholders(objcopy);
      }
    } else if (locationObject.position.includes('Radio')) {
      if (placeholders.RADIO.some((e) => e.position === locationObject.position)) {
        placeholders.RADIO.map((e: any) => {
          if (e.position === locationObject.position) {
            return locationObject;
          }
          return e;
        });
      } else {
        const objcopy = placeholders;
        objcopy.RADIO.push(locationObject);
        setPlaceholders(objcopy);
      }
    } else if (locationObject.position.includes('Print')) {
      if (placeholders.PRINT.some((e) => e.position === locationObject.position)) {
        placeholders.PRINT.map((e: any) => {
          if (e.position === locationObject.position) {
            return locationObject;
          }
          return e;
        });
      } else {
        const objcopy = placeholders;
        objcopy.PRINT.push(locationObject);
        setPlaceholders(objcopy);
      }
    } else if (locationObject.position.includes('OOH')) {
      if (placeholders.OOH.some((e) => e.position === locationObject.position)) {
        placeholders.OOH.map((e: any) => {
          if (e.position === locationObject.position) {
            return locationObject;
          }
          return e;
        });
      } else {
        const objcopy = placeholders;
        objcopy.OOH.push(locationObject);
        setPlaceholders(objcopy);
      }
    } else if (locationObject.position.includes("Cinema")) {
      if (placeholders.CINEMA.some((e) => e.position === locationObject.position)) {
        placeholders.CINEMA.map((e: any) => {
          if (e.position === locationObject.position) {
            return locationObject;
          }
          return e;
        });
      } else {
        const objcopy = placeholders;
        objcopy.CINEMA.push(locationObject);
        setPlaceholders(objcopy);
      }
    } else {
      if (placeholders.DIGITAL.some((e) => e.position === locationObject.position)) {
        placeholders.DIGITAL.map((e: any) => {
          if (e.position === locationObject.position) {
            const kk = locationObject.metrics?.map((o: any) => {
              delete o.__typename;
              return o;
            })

            return { ...locationObject, metrics: kk };
          }
          return e;
        });
      } else {
        const objcopy = placeholders;
        if (Array.isArray(locationObject.metrics)) {

          const kk = locationObject.metrics?.map((o: any) => {
            delete o.__typename;
            return o;
          })
          locationObject.metrics = kk;
        }
        objcopy.DIGITAL.push(locationObject);
        setPlaceholders(objcopy);
      }
    }
  };

  const publishCampaign = useCallback(

    async (summaryPlaceholders?: PlaceholderObject) => {
      console.log("PB", {
        publishInput: {
          campaignId: activeCampaign?.campaignId?.toString(),
          placeholders: summaryPlaceholders
            ? summaryPlaceholders
            : placeholders[dashType === "INSOURCE_DATA" ? "DIGITAL" : dashType as keyof typeof placeholders],
          dashboardType: DashboardTypes[dashType === "INSOURCE_DATA" ? "DIGITAL" : dashType as keyof typeof DashboardTypes],
          clientCode: activeCampaign?.ClientCode,
          clientCountry: activeCampaign?.ClientCountry,
          subCampaignId: activeSubCampaign?.subcampaignId?.toString(),
        }
      })

      try {
        const publish = (await API.graphql({
          query: publishOutputLocation_v2,
          variables: {
            publishInput: {
              campaignId: activeCampaign?.campaignId?.toString(),
              placeholders: summaryPlaceholders
                ? summaryPlaceholders
                : placeholders[dashType === "INSOURCE_DATA" ? "DIGITAL" : dashType as keyof typeof placeholders],
              dashboardType: DashboardTypes[dashType === "INSOURCE_DATA" ? "DIGITAL" : dashType as keyof typeof DashboardTypes],
              clientCode: activeCampaign?.ClientCode,
              clientCountry: activeCampaign?.ClientCountry,
              subCampaignId: activeSubCampaign?.subcampaignId?.toString(),
            },
            Environment: getEnvironment,
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as { data: PublishOutputLocation_v2Mutation };
        console.log('PUBLISH', publish);
        if (publish.data.publishOutputLocation_v2?.startsWith('Successfully published')) {
          setIsPublished(true);
          if (activeCampaign?.status !== FlowchartCampaignStatus.CAMPAIGN_PARTLY_PUBLISHED) {
            if (dashType !== DashboardTypes.FLOWCHART) {
              UpdateCampaign(activeCampaign, FlowchartCampaignStatus.CAMPAIGN_PARTLY_PUBLISHED);
              window.location.reload();

            } else {
              setCampaignOverflowKPIquery(undefined)
            }

          } else {
            if (dashType !== DashboardTypes.FLOWCHART) {
              window.location.reload();

            }
          }
        }
      } catch (e) {
        console.log('NOT PUBLISHED BCZ', e);
      }
    },
    [activeCampaign, activeSubCampaign, dashType, getEnvironment, placeholders],
  );

  async function unpublishCampaign() {
    try {
      // eslint - disable - next - line @typescript-eslint / no - unused - vars
      const unpublish = (await API.graphql({
        query: unPublishCampaign,
        variables: {
          campaign_id: activeCampaign?.campaignId?.toString(),
          clientCode: activeCampaign?.ClientCode,
          clientCountry: activeCampaign?.ClientCountry,
          dashboardType: DashboardTypes[dashType === "INSOURCE_DATA" ? "DIGITAL" : dashType as keyof typeof DashboardTypes],
          subCampaignId: activeSubCampaign?.subcampaignId?.toString(),
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })) as { data: UnPublishCampaignMutation };

      console.log('unpublish', unpublish);

      if (unpublish.data.unPublishCampaign?.StatusCode === 200) {
        setIsPublished(false);


        if (activeCampaign?.status === FlowchartCampaignStatus.CAMPAIGN_PARTLY_PUBLISHED) {

          const anyPublished = () => {
            const arr: any = [];
            if (activeCampaign?.subcampaign) {
              for (const [key, value] of Object.entries(activeCampaign.subcampaign)) {
                const publishedobj = (value as any).published;
                //check all propertues of publishedobj and if any is true return true
                const isPublished = Object.values(publishedobj).filter(e => e === true);
                arr.push(...isPublished);
              }
            }
            return arr.length > 1
          };


          const q = anyPublished();
          console.log('ANY PUBLISHED', q, activeCampaign)


          if (!q) {
            const rollbackTo = rollbackStatus?.hasPlan ? "PLANNING_DONE" : !rollbackStatus?.hasPlan && rollbackStatus?.hasBrief ? "BRIEF_COMPLETED" : "CREATED"
            UpdateCampaign(activeCampaign, FlowchartCampaignStatus[rollbackTo]);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            // const campaign = campaignList?.find((campaign: any) => campaign.campaignId === activeCampaign?.campaignId);
            // const newCampaign = {
            //   ...campaign,
            //   status: rollbackTo,
            //   subcampaign: { ...campaign?.subcampaign, [activeSubCampaign?.subcampaignId as number]: { ...campaign?.subcampaign[activeSubCampaign?.subcampaignId as number], published: { ...campaign?.subcampaign[activeSubCampaign?.subcampaignId as number].published, [dashType]: false } } }
            // } as ACdetails;

            // const newCampaignList = campaignList?.map((campaign: ACdetails) => {
            //   if (campaign?.campaignId === activeCampaign?.campaignId) {
            //     return newCampaign;
            //   } else {
            //     return campaign;
            //   }
            // }) as ACdetails[];
            // setPermanentCampaignList(newCampaignList);
          } else {
            window.location.reload();
          }
        } else if (activeCampaign?.status === FlowchartCampaignStatus.CAMPAIGN_PUBLISHED) {
          if (dashType === DashboardTypes.FLOWCHART) {
            // const rollbackTo = rollbackStatus?.hasPlan ? "PLANNING_DONE" : !rollbackStatus?.hasPlan && rollbackStatus?.hasBrief ? "BRIEF_COMPLETED" : "CREATED"
            UpdateCampaign(activeCampaign, FlowchartCampaignStatus.CAMPAIGN_PARTLY_PUBLISHED);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            // const campaign = campaignList?.find((campaign: any) => campaign.campaignId === activeCampaign?.campaignId);
            // const newCampaign = {
            //   ...campaign,
            //   status: rollbackTo,
            //   subcampaign: { ...campaign?.subcampaign, [activeSubCampaign?.subcampaignId as number]: { ...campaign?.subcampaign[activeSubCampaign?.subcampaignId as number], published: { ...campaign?.subcampaign[activeSubCampaign?.subcampaignId as number].published, [dashType]: false } } }
            // } as ACdetails;

            // const newCampaignList = campaignList?.map((campaign: ACdetails) => {
            //   if (campaign?.campaignId === activeCampaign?.campaignId) {
            //     return newCampaign;
            //   } else {
            //     return campaign;
            //   }
            // }) as ACdetails[];
            // setPermanentCampaignList(newCampaignList);
          }
        }
      }

    } catch (e) {
      console.log('NOT UNPUBLISHED BCZ', e);
    }
  }

  useEffect(() => {
    setPlaceholders({
      DIGITAL: [] as any[],
      TV: [] as any[],
      RADIO: [] as any[],
      OOH: [] as any[],
      PRINT: [] as any[],
      CINEMA: [] as any[],
    });
  }, [activeCampaign, dashType, fetchedPlaceholders]);



  useEffect(() => {
    const checkDashPublished = (
      subCampaignO:
        | {
          campaignId: string;
          subCampaignId: string;
          published: publishedType;
          __typename: string;
        }
        | undefined,
      dash: DashboardTypes,
    ) => {
      if (subCampaignO === undefined) return false;
      if (dash === 'DIGITAL') {
        return subCampaignO?.published.DIGITAL === true;
      }
      if (dash === 'TV') {
        return subCampaignO?.published.TV === true;
      }
      if (dash === 'RADIO') {
        return subCampaignO?.published.RADIO === true;
      }
      if (dash === 'PRINT') {
        return subCampaignO?.published.PRINT === true;
      }
      if (dash === 'OOH') {
        return subCampaignO?.published.OOH === true;
      } if (dash === 'CINEMA') {
        return subCampaignO?.published.CINEMA === true;
      }
      return false;
    };
    // console.log(activeCampaign?.ClientCode, activeCampaign?.ClientCountry, activeCampaign?.authorized, activeCampaign?.campaignId, activeSubCampaign?.subcampaignId, activeSubCampaignObject, checkDashPublished, dashType)
    const fetch = async () => {
      try {
        console.count("fetch")
        const response = (await API.graphql({
          query: getPublishedOutput,
          variables: {
            clientCode: activeCampaign?.ClientCode,
            clientCountry: activeCampaign?.ClientCountry,
            campaign_id: activeCampaign?.campaignId,
            dashboardType: DashboardTypes[dashType as keyof typeof DashboardTypes],
            subCampaignId: activeSubCampaign?.subcampaignId?.toString(),
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        })) as { data: GetPublishedOutputQuery };
        const placeholdersData = response.data.getPublishedOutput?.placeholders;
        if (placeholdersData) {
          console.log("PLACEHOLDERS DATA", placeholdersData)
          setPublishedData(placeholdersData as Placeholder[]);
        }
      } catch (e) {
        console.error(e);
      }
    };
    // this call should be only if activecampaign.authorized = false

    const shouldThisTrygFetchPublishedData = !userCanDoInsource || (userCanDoInsource && dashType !== DashboardTypes.INSOURCE_DATA)

    if (dashType !== null) {
      if (activeCampaign?.authorized === false && shouldThisTrygFetchPublishedData && checkDashPublished(activeSubCampaignObject, dashType)) {
        fetch();
      }
    }

  }, [activeCampaign?.ClientCode, activeCampaign?.ClientCountry, activeCampaign?.authorized, activeCampaign?.campaignId, activeSubCampaign?.subcampaignId, activeSubCampaignObject, dashType]);




  const [
    campaignOverFlowdata,
    campaignOverFlowqueryStatus,
    campaignOverFlowerror,
    campaignOverFlowqueryId,
    campaignOverFlowoutputLocation,
  ] = useDataQuery({
    query: campaignOverflowKPIquery,
    querySource: 'CAMPAIGN OVERFLOW KPI' /* , publishedOutputLocation: publishedOutputLocation  */,
  });
  useEffect(() => {
    if (campaignOverFlowoutputLocation.length > 0) {
      const placeholderObject = {
        position: 'Summary',
        outputlocation: campaignOverFlowoutputLocation,
      };
      publishCampaign(placeholderObject);
    }
  }, [campaignOverFlowoutputLocation, publishCampaign]);



  const handlePublishSummary = () => {

    if (activeCampaign && overflowKPIquery) {
      const newObject = structuredClone(overflowKPIquery);
      newObject.clients = [
        {
          country: countryEnum[activeCampaign.ClientCountry as keyof typeof countryEnum],
          client: activeCampaign.ClientCode,
        },
      ];
      newObject.query.filters = [
        {
          column: 'campaign_id',
          filter: {
            filtertype: filtertype.EQUAL,
            datatype: datatype.NUMBER,
            value: activeCampaign?.campaignId.toString(),
          },
        },
      ];
      setCampaignOverflowKPIquery(newObject);

      UpdateCampaign(activeCampaign, FlowchartCampaignStatus.CAMPAIGN_PUBLISHED);

      const campaign = permanentCampaignList?.find((campaign: any) => campaign.campaignId === activeCampaign?.campaignId);
      const newCampaign = {
        ...campaign,
        status: 'CAMPAIGN_PUBLISHED',
      } as ACdetails;

      const newCampaignList = permanentCampaignList?.map((campaign: ACdetails) => {
        if (campaign?.campaignId === activeCampaign?.campaignId) {
          return newCampaign;
        } else {
          return campaign;
        }
      }) as ACdetails[];

      setPermanentCampaignList(newCampaignList);
    }
  };

  return (
    <PublishContext.Provider
      value={{
        publishCampaign,
        updateOLArray,
        publishedData,
        unpublishCampaign,
        setPublishedData,
        isPublished,
        handlePublishSummary,
        campaignOverFlowqueryStatus,
        removePlaceholderfromPublishArray,
        placeholders
      }}
    >
      {props.children}
    </PublishContext.Provider>
  );
};
